import React, { useState, useEffect } from "react";
import { HiLocationMarker, HiOutlineMail } from "react-icons/hi";
import { IoMdCall } from "react-icons/io";
import { FaFacebookF, FaTwitter, FaYoutube } from "react-icons/fa";
import DealerPageNavPanel from "./DealerPageNavPanel";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDealer } from "../../redux/actions/dealerActions";
import { getWavs } from "../../redux/actions/wavActions";
import Cookies from "universal-cookie";
const DealerPage = () => {
  const [dealer, setDealer] = useState({});
  const [showNumber, setShowNumber] = useState(true);
  const [dealerWavs, setDealerWavs] = useState([]);
  const wavs = useSelector((state) => state.wavs.list);
  const cookies = new Cookies();
  const user = cookies.get("user");
  const params = useParams();
  const dispatch = useDispatch();
  const selectedTask = useSelector((state) => state.dealers.selected);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getDealer(params.id));
    dispatch(getWavs());
  }, []);
  useEffect(() => {
    setDealer(selectedTask);
  }, [selectedTask]);

  useEffect(() => {
    const sortedWavs = wavs.filter((wav) => {
      if (wav.owner) {
        if (wav.owner._id === params.id) {
          return wav;
        }
      }
    });
    setDealerWavs(sortedWavs);
  }, [wavs]);

  return (
    <div className="dealer-page">
      {dealer && (
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="dealer-page-header">
                <div className="dealer-name">
                  <h3>{dealer.name}</h3>
                  <div>Reviews</div>
                </div>
                {/* <div className="dealer-logo">
                  <img
                    src="https://www.wavsgb.com/wp-content/uploads/wavsGB-logo.png"
                    alt="company-logo"
                  />
                </div> */}
              </div>
              <div className="dealer-page-details">
                <div className="dealer-page-details-logo">
                  <img
                    src={
                      dealer.logo === ""
                        ? "/assets/user.jpg"
                        : `https://dev.wavcompare.com/uploads/${dealer.logo}`
                    }
                    alt="dealer logo"
                    className="img-fluid"
                  />
                </div>
                <div className="dealer-page-details-items">
                  <div className="dealer-page-details-item">
                    <div className="logo">
                      <HiLocationMarker />
                    </div>
                    <div className="text">
                      <h6>LOCATION</h6>
                      <p>{dealer.address}</p>
                    </div>
                  </div>
                  <div className="dealer-page-details-item">
                    <div className="logo">
                      <HiOutlineMail />
                    </div>
                    <div className="text">
                      <h6>EMAIL</h6>
                      <p>{dealer.email}</p>
                    </div>
                  </div>
                  <div className="dealer-page-details-item">
                    <div className="logo">
                      <IoMdCall />
                    </div>
                    <div className="text">
                      <h6> SALES PHONE</h6>
                      <p>
                        {showNumber ? dealer.phone_number : "****"}
                        {/* <span
                          style={{ cursor: "pointer" }}
                          className="ml-3"
                          onClick={() => setShowNumber(!showNumber)}
                        >
                          Show Number
                        </span> */}
                      </p>
                    </div>
                  </div>
                  <div className="dealer-page-details-contact">
                    <div className="text website">
                      {dealer && dealer.links && dealer.links.web_site !== "#" && (
                        <a href={`${dealer.links.web_site}`} target="_blank">
                          View Website
                        </a>
                      )}
                    </div>
                    {dealer.links && (
                      <div className="icons">
                        {dealer.links.facebook !== "#" && (
                          <div className="icon">
                            <a
                              href={
                                dealer && dealer.links
                                  ? dealer.links.facebook
                                  : "#"
                              }
                              target="_blank"
                            >
                              <FaFacebookF />
                            </a>
                          </div>
                        )}
                        {dealer.links.twitter !== "#" && (
                          <div className="icon">
                            <a
                              href={
                                dealer && dealer.links
                                  ? dealer.links.twitter
                                  : "#"
                              }
                              target="_blank"
                            >
                              <FaTwitter />
                            </a>
                          </div>
                        )}
                        {dealer.links.youtube !== "#" && (
                          <div className="icon">
                            <a
                              href={dealer.links ? dealer.links.youtube : "#"}
                              target="_blank"
                            >
                              <FaYoutube />
                            </a>
                          </div>
                        )}{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="dealer-page-nav-panel">
                {dealer && <DealerPageNavPanel dealer={dealer} />}
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-2 dealer-map">
              <div className="map">
                <img
                  src="https://www.reviewgeek.com/p/uploads/2020/04/fadc14dd.jpg?height=200p&trim=2,2,2,2"
                  alt=""
                />
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default DealerPage;
