import React from "react";

const SellYourWavAdvertise = () => {
  return (
    <div className="sell-your-wav-ad">
      <div className="top">
        <h3>Advertise quickly to 100's of buyers.</h3>
        <p>
          We are the UK's only dedicated platform to buy and sell wheelchair
          accessible vehicles and make it easy for anyone to{" "}
          <strong>sell a WAV quickly.</strong>
        </p>
        <p>
          100's of people visit us everyday looking to{" "}
          <strong>buy only WAVs</strong> - so if you have one for sale start
          below and advertise today!
        </p>
      </div>
      <div className="bottom">
        <h3>3 easy steps to selling your WAV</h3>
        <div className="steps">
          <div className="step">
            <div>Step 1</div>
            <div>Enter your email below and get 7 days free</div>
          </div>
          <div className="step">
            <div>Step 2</div>
            <div>Complete advert listing and upload photos</div>
          </div>
          <div className="step">
            <div>Step 3</div>
            <div>Publish live and connect directly with buyers</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellYourWavAdvertise;
