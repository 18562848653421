import React from "react";
import { useEffect } from "react";
import "./style.css";
const Dmuk = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="dmuk">
      <div className="colored-header">
        <div className="text">
          Disabled Motoring UK is the charity which supports disabled drivers,
          passengers and Blue Badge holders.
        </div>
      </div>
      <div className="main-content">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div>
              We work with government and businesses across the UK in order to
              improve parking, refuelling, and access provision for disabled
              people, so that they can access the goods and services that they
              need.
            </div>
            <div>
              Disabled Motoring UK provides a mobility information service to
              the general public where we can give basic information and
              signpost to relevant organisations. Our members receive access to
              our Information Officers who can help with more complex questions
              and in depth knowledge.
            </div>
            <div className="logo">
              <img src="/assets/DMUK.png" alt="dmuk logo" />
            </div>
            <div className="find-out-more">
              <div>
                Find out more: <a href="#">www.disabledmotoring.org</a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src="https://i.ytimg.com/vi/AO66KltOLZA/maxresdefault.jpg"
              alt="car"
              className="img-fluid mt-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dmuk;
