import React from "react";

const HelpAndAdviceColorCards = () => {
  return (
    <div className="help-and-advice-color-cards">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="yellow-card">
              <h2>
                Why should you buy a <span>Wheelchair Accessible Vehicle?</span>
              </h2>
              <p>
                Purchasing a WAV can enrich your life. It will give you the
                freedom to visit new places as well as making visits to the
                shops or to the hospital a safe and pleasant experience. No more
                waiting for public transport, no more difficult transfers into a
                regular car, no more relying on someone else. With your own
                wheelchair accessible vehicle you can enjoy life to the full.
              </p>
            </div>
            <div className="red-card">
              <h2>
                Buying a <span> wheelchair accessible vehicle</span>
              </h2>
              <p>
                Always purchase from a reputable and recommended dealer, or, if
                you are buying privately do so through a site such as WAV
                Compare. We can help you to find the new or used vehicle that
                best suits your needs. When you find your ideal vehicle, be sure
                to take the vehicle for a test drive and check our all aspects
                to make sure you feel safe and comfortable using them.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpAndAdviceColorCards;
