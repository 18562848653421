import React from "react";
import { Helmet } from "react-helmet";

const withHelmet = (WrappedComponent, title, description) => {
  return (props) => {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />

          <title>{title}</title>

          <meta name="description" content={description} />
        </Helmet>
        <WrappedComponent {...props} />
      </React.Fragment>
    );
  };
};

export default withHelmet;
