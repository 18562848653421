import './style.css';
import CameraIconSvg from "../../images/camera_icon.svg";
import { ReactComponent as DeleteSvg } from "../../images/delete-2.svg";
import { useState } from 'react';
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { toast } from 'react-toastify';

const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;



const WavTile = ({ item, setEditing, setWav, deleteWav, toggleWavStatus }) => {
    const cookies = new Cookies();
    const token = cookies.get("jwt");

    const toggleStatus = async (id) => {
        let formData = new FormData();
        formData.append("status", item.status == "active" ? "inactive" : "active");
        const mainUrl = `${url}/wav/${id}`;
        const method = "PATCH";
        try {
            const response = await fetch(mainUrl, {
                method,
                body: formData,
                headers: {
                    authorization: `${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                toast.success("Status updated successfully")
                toggleWavStatus(id, item.status == "active" ? "inactive" : "active")
            } else {
                const data = await response.json();
                toast.success("Status doesnot updated")
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="active_list position-relative">
                <span className={`list_label1 ${item.status != "active" && "ended_label1"}`}>{item.status.toUpperCase()}</span>
                <div className="row list_row_reverse">
                    <div className="col-lg-7 col-md-12 mb-2  text-left">
                        <h2>{item.title.toUpperCase()}</h2>
                        <p>{item.description}</p>
                        <div className="row">
                            <div className="col-md-6 mb-2 text-left">
                                <ul className="m-0 p-0 last_border">
                                    <li>
                                        <h4>Make</h4>
                                        <h5>{item.make.toUpperCase()}</h5>
                                    </li>
                                    <li>
                                        <h4>YEAR REG</h4>
                                        <h5>{item.year}</h5>
                                    </li>
                                    <li>
                                        <h4>COLOUR</h4>
                                        <h5>{item.exterior_colour.toUpperCase()}</h5>
                                    </li>
                                    <li>
                                        <h4>FUEL</h4>
                                        <h5>{item.fuel_type.toUpperCase()}</h5>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6 mb-2 text-left">
                                <ul className="m-0 p-0 last_border">
                                    <li>
                                        <h4>MODEL</h4>
                                        <h5>{item.models.toUpperCase()}</h5>
                                    </li>
                                    <li>
                                        <h4>CONDITION</h4>
                                        <h5>{item.condition.toUpperCase()}</h5>
                                    </li>
                                    <li>
                                        <h4>SEATING</h4>
                                        <h5>{item.seating.toUpperCase()}</h5>
                                    </li>
                                    {/* <li>
                                        <h4>Draft</h4>
                                        <h5>{item.is_draft == 1 ? "YES" : "NO"}</h5>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 mb-2">
                        <div className="car_list_img position-relative">
                            <img src={`https://dev.wavcompare.com/uploads/${(item.featured_image && item.featured_image?.replace("images/", "images/thumbnails/")) || item.images && item.images[0] && item.images[0].replace("images/", "images/thumbnails/")}` ||
                                "https://www.exploremobility.co.uk/assets/imgs/jpgs/slide1.jpg"} alt="img" className="main_car_img1" />
                            <span className="photos_num1">
                                <img src={CameraIconSvg} alt="camera" />
                                {item.images.length}</span>
                        </div>
                        <div className="list_btn mt-4">
                            <button className="btn manage_extend_btn" onClick={() => {
                                setEditing(true)
                                setWav(item)
                            }}>EDIT LISTING</button>

                            <button className={`btn ${item.status == "active" ? "manage_extend_btn" : "end_btn"}`} onClick={() => {
                                toggleStatus(item._id)
                            }}>{item.status == "active" ? "Deactivate Listing" : "Activate Listing"}</button>

                            <DeleteSvg height={24} width={24} stroke='white' className="btn end_btn" onClick={() => {
                                Swal.fire({
                                    title: 'Are you sure?',
                                    text: "Removing will delete all records of your listing permanently",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Yes, delete it!'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        deleteWav(item._id)
                                        Swal.fire(
                                            'Deleted!',
                                            'Your file has been deleted.',
                                            'success'
                                        )
                                    }
                                })
                            }} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default WavTile