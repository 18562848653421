export const formatWavUrlString = (wav) => {
  let str = `${wav.make && wav.make.split(" ").join("-")}-${wav.models && wav.models.split(" ").join("-")
    }-${wav.stock_id}`.toLowerCase();
  return str;
};

// Create our number formatter.
export const poundsformatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 0, // Set to 0 to avoid decimal values
  maximumFractionDigits: 0,
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function isNumberKey(evt) {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
}

export const getCheapestAndCostliestWavPrice = (wavs) => {
  //console.log("wavs------>", wavs);
  let wavPriceArr = wavs.map((wav) => {
    wav.price_2 = wav.price.replace('£', "");
    //wav.price_2 = wav.price.replace(/[^0-9]/g, "");

    console.log(wav.price_2);

    let price = wav.price_2 && wav.price_2 != "" ? parseInt(wav.price_2) : 0;
    return price;
  });

  const filteredValues = wavPriceArr.filter(value => !isNaN(value));
  // console.log("wavs", wavs);
  //console.log("wavPriceArr", filteredValues);
  let lowestVal = Math.min(...filteredValues);
  //console.log("lowestVal", lowestVal);
  let highestVal = Math.max(...filteredValues);
  //console.log("highestVal", highestVal);

  // console.log("wavs", wavs)
  // console.log("lowestVal", {
  //   lowestVal,
  //   highestVal
  // })
  return {
    lowestVal,
    highestVal,
  };
};

export const filterWavsAccordingToPriceRange = (wavs, range) => {
  let resp = wavs.filter((wav) => {
    let price = 0;
    if (Object.entries(wav).length > 0) {
      wav.price_2 = wav.price.replace(/[^0-9]/g, "");
      price = wav.price_2 ? parseFloat(wav.price_2) : 0;
    }
    if (price >= range[0] && price <= range[1]) {
      return wav;
    }
  });
  return resp;
};

export const mileageFilterOptions = [
  {
    label: "UP TO 10,000 MILES",
    value: 10000,
  },
  {
    label: "UP TO 20,000 MILES",
    value: 20000,
  },
  {
    label: "UP TO 30,000 MILES",
    value: 30000,
  },
  {
    label: "UP TO 40,000 MILES",
    value: 40000,
  },
  {
    label: "UP TO 50,000 MILES",
    value: 50000,
  },
  {
    label: "UP TO 60,000 MILES",
    value: 60000,
  },
  {
    label: "UP TO 70,000 MILES",
    value: 70000,
  },
  {
    label: "UP TO 80,000 MILES",
    value: 80000,
  },
  {
    label: "UP TO 90,000 MILES",
    value: 90000,
  },
  {
    label: "UP TO 100,000 MILES",
    value: 100000,
  },
  {
    label: "UP TO 110,000 MILES",
    value: 110000,
  },
  {
    label: "UP TO 120,000 MILES",
    value: 120000,
  },
  {
    label: "UP TO 130,000 MILES",
    value: 130000,
  },
  {
    label: "UP TO 140,000 MILES",
    value: 140000,
  },
  {
    label: "UP TO 150,000 MILES",
    value: 150000,
  },
  {
    label: "UP TO 160,000 MILES",
    value: 160000,
  },
  {
    label: "UP TO 170,000 MILES",
    value: 170000,
  },
  {
    label: "UP TO 180,000 MILES",
    value: 180000,
  },
  {
    label: "UP TO 190,000 MILES",
    value: 190000,
  },
  {
    label: "UP TO 200,000 MILES",
    value: 200000,
  },
  {
    label: "UP TO 210,000 MILES",
    value: 210000,
  },
  {
    label: "UP TO 220,000 MILES",
    value: 220000,
  },
  {
    label: "UP TO 230,000 MILES",
    value: 230000,
  },
  {
    label: "UP TO 240,000 MILES",
    value: 240000,
  },
  {
    label: "UP TO 250,000 MILES",
    value: 250000,
  },
];
