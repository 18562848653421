import React from "react";

const DisabledMotoring = ({ history, setMenuOpen }) => {
  return (
    <div className="seller">
      <div className="text">
        <h1 className="longer">Disabled Motoring UK</h1>
        <h4 className="longer">
          Disabled Motoring UK is the charity which supports disabled drivers,
          passengers and Blue Badge holders.
        </h4>
        <button
          onClick={() => {
            history.push("/dmuk");
            setMenuOpen(false);
          }}
        >
          Click Here
        </button>
      </div>
      <div className="img">
        <img src="/assets/DM-UK.jpg" alt="seller" className="img-fluid" />
      </div>
    </div>
  );
};

export default DisabledMotoring;
