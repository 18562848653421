import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";

const ProductWavFeatures = ({ wav }) => {
  return (
    <div className="product-card-wav-features">
      <div className="row w-100 mx-auto">
        {wav.features &&
          wav.features.map((feature) => (
            <div
              style={{ textTransform: "capitalize" }}
              key={feature}
              className="product-card-wav-feature col-sm-6 col-md-4 col-lg-3"
            >
              <span>
                <AiFillCheckCircle />
              </span>
              {feature}
            </div>
          ))}
      </div>
      {/* <div className="product-card-wav-feature">
        <ul>
          <li>
            <span>
              <AiFillCheckCircle />
            </span>
            Alloy Wheels
          </li>
          <li>
            <span>
              <AiFillCheckCircle />
            </span>
            Electric heated seats
          </li>
          <li>
            <span>
              <AiFillCheckCircle />
            </span>
            Power Mirrors
          </li>
        </ul>
      </div>
      <div className="product-card-wav-feature">
        <ul>
          <li>
            <span>
              <AiFillCheckCircle />
            </span>
            Alloy Wheels
          </li>
          <li>
            <span>
              <AiFillCheckCircle />
            </span>
            Electric heated seats
          </li>
          <li>
            <span>
              <AiFillCheckCircle />
            </span>
            Power Mirrors
          </li>
        </ul>
      </div>
      <div className="product-card-wav-feature">
        <ul>
          <li>
            <span>
              <AiFillCheckCircle />
            </span>
            Alloy Wheels
          </li>
          <li>
            <span>
              <AiFillCheckCircle />
            </span>
            Electric heated seats
          </li>
          <li>
            <span>
              <AiFillCheckCircle />
            </span>
            Power Mirrors
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default ProductWavFeatures;
