import React, { useEffect, useState } from "react";
import { MdPhotoCamera } from "react-icons/md";
import { AiOutlineUpload, AiFillEye, AiFillCar, AiOutlineDelete } from "react-icons/ai";
import moment from "moment";
import Cookies from "universal-cookie";
import { getMakes, getModels, getWavs } from "../../redux/actions/wavActions";
import { useDispatch, useSelector } from "react-redux";
import { getDealer } from "../../redux/actions/dealerActions";
import { useHistory } from "react-router-dom";
import { tr } from "date-fns/locale";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as HollowStar } from '../../images/star-line-yellow-icon.svg'
import { ReactComponent as Star } from '../../images/star-symbol-icon.svg'
import { toast } from "react-toastify";
import Features from "./Features";
import Accordion from "./Accordion";
import { ReactComponent as UploadSvg } from '../../images/upload.svg'

const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;
let i;
let years = [];
for (i = moment().year(); i >= moment().year() - 100; i--) {
  years.push(
    <option value={i} key={`year-${i}`}>
      {i}
    </option>
  );
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Warning!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Centered Modal</h4> */}
        <p style={{ color: "red" }}>
          You do not have enough credits to make an ad. Please fill your
          charge
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={() => {
          window.open("/sell-your-wav-private-seller", "_blank");
          // history.push("/sell-your-wav-private-seller")
        }}>
          Buy Credits
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const AddOrEdit = ({ name, setEditing, setAdding, wav, getUser }) => {
  // console.log("WAV------", wav)
  const [features, setFeatures] = useState([]);
  const mileageInt = wav ? parseInt(wav.mileage.replaceAll(`\"`, "")) : 0;
  const [existingFeatures, setExistingFeatures] = useState(
    wav ? wav.features : []
  );
  const [price, setPrice] = useState(wav ? wav.price : "");
  const [condition, setCondtion] = useState(wav ? wav.condition : "used");
  const [make, setMake] = useState(wav ? wav.make.trim().toLowerCase() : "");
  const [title, setTitle] = useState(wav ? wav.title : "");
  const [models, setModels] = useState(wav ? wav.models.trim().toLowerCase() : "");
  const [year, setYear] = useState(wav ? wav.year : "2022");
  const [body, setBody] = useState(wav ? wav.body : "Estate");
  const [engine, setEngine] = useState(wav ? wav.engine : 1.2);
  const [size, setSize] = useState(wav ? wav.size : "Large");
  const [entrance, setEntrance] = useState(
    wav ? wav.entrance : "Auto Side Step"
  );
  const [doors, setDoors] = useState(wav ? wav.doors : "1 door");
  const [mileage, setMileage] = useState(mileageInt);
  const [transmission, setTransmission] = useState(
    wav ? wav.transmission : "Automatic"
  );
  const [wav_type, setWavType] = useState(
    wav ? wav.wav_type : "Rear Passenger"
  );
  const [space, setSpace] = useState(wav ? wav.space : "Lowered Floor");
  const [stock_id, setStockId] = useState(wav ? wav.stock_id : "");
  const [fuel_type, setFuelType] = useState(wav ? wav.fuel_type : "LPG");
  const [exterior_colour, setColor] = useState(wav ? wav.exterior_colour : "");
  const [seating, setSeating] = useState(wav ? wav.seating : "");
  const [wheelchairs, setWheelchairs] = useState(wav ? wav.wheelchairs : "");
  const [conversion, setConversion] = useState(
    wav ? wav.conversion : "Allied Mobility"
  );
  const [status, setStatus] = useState(wav ? wav.status : "active");
  const [description, setDescription] = useState(wav ? wav.description : "");
  const [images, setImages] = useState([]);
  const [exisitingImages, setExistingImages] = useState(wav ? wav.images : []);
  const [deletedImages, setDeletedImages] = useState([]);
  const [success, setSucces] = useState(false);
  const [error, setError] = useState(false);
  const [flagged, setFlagged] = useState(false);
  const [coinError, setCoinError] = useState(false);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("jwt");
  const user = cookies.get("user");
  const dealerFromState = useSelector((state) => state.dealers.selected);
  const history = useHistory();
  const [isDraft, setIsDraft] = useState(0)

  const [modalShow, setModalShow] = useState(false);

  const { makes: makesList, models: modelsList } = useSelector((state) => state.wavs)

  const [filteredModelsList, setFilteredModelsList] = useState([])

  console.log("make", make)
  // console.log("makes", makesList)
  // console.log("mdoels", modelsList)
  useEffect(() => {
    dispatch(getDealer(user._id));
    getFeatures();
    if (parseInt(dealerFromState.coins) < 1) {
      setCoinError(true);
      name === "adding" &&
        setModalShow(true)
    }
  }, []);


  const [imageFiles, setImageFiles] = useState([]);
  const [imagesarr, setImagesarr] = useState([]);

  const [featuredImage, setFeaturedImage] = useState("")

  const [selectedFeaturedImage, setSelectedFeatureImage] = useState("")


  useEffect(() => {
    console.log("IN USE EFGF")
    if ((wav && wav.featured_image)) {
      setFeaturedImage("")

      setSelectedFeatureImage(wav.featured_image)
    }
    else {
      setSelectedFeatureImage("")
      setFeaturedImage("0")
    }

  }, [])

  // console.log("featuredImage",featuredImage)
  const changeHandler = (e) => {
    if (e.target.files.length > 40) {
      toast.error("Only 40 images are allowed in total")
      return
    }
    const { files } = e.target;
    const validImageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // if (file.type.match(imageTypeRegex)) {
      validImageFiles.push(file);
      // }
    }
    if (validImageFiles.length) {
      setImageFiles(validImageFiles);
      return;
    }
    // alert("Selected images are not of valid type!");
  };

  useEffect(() => {
    const images2 = [], fileReaders = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            // images2.push(result)
            images2.push({ filename: file.name, fileData: result, fileType: file.type })
          }
          if (images2.length === imageFiles.length && !isCancel) {
            setImagesarr(images2);
          }
        }
        fileReader.readAsDataURL(file);
      })
    };
    return () => {
      isCancel = true;
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [imageFiles]);


  const handleDelete = (idx) => {
    let filteredImages = imagesarr.filter((image, index) => index != idx)
    setImagesarr(filteredImages);

    let filtered_images = []
    for (let i = 0; i < images.length; i++) {
      if (i != idx)
        filtered_images.push(images[i])
    }
    setImages(filtered_images)
  }


  const getFeatures = async () => {
    try {
      const response = await fetch(`${url}/features/all`);
      if (response.ok) {
        const data = await response.json();
        const features = data.data.features;
        if (wav) {
          const array = features.filter(
            (filter) => existingFeatures.indexOf(filter.title) !== -1
          );

          for (let feature of array) {
            const index = features.indexOf(feature);
            features[index].selected = true;
          }
          setFeatures(features);
        } else {
          setFeatures(features);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (wav) {
      setIsDraft(wav.is_draft)
    }
  }, [])


  useEffect(() => {
    // FETCH ACTIVE MAKES AND MODELS
    dispatch(getMakes());
    dispatch(getModels());
  }, [])

  const [savingData, setSavingData] = useState(false)

  const doWav = async (type, isDraft = 0, id) => {
    // console.log("doWav")
    setSavingData(true)

    let formData = new FormData();

    // console.log("formData", formData)
    if (images && images.length > 0) {
      for (let image of images) {
        formData.append("images", image, image.name);
      }
    }

    formData.append("is_draft", isDraft)
    formData.append("featured_image", featuredImage)
    formData.append("selected_featured_image", selectedFeaturedImage)

    formData.append("price", price);
    formData.append("condition", condition);
    formData.append("make", make);
    formData.append("models", models);
    formData.append("year", parseInt(year));
    formData.append("body", body);
    formData.append("engine", engine);
    formData.append("size", size);
    formData.append("entrance", entrance);
    formData.append("doors", doors);
    // formData.append("mileage", JSON.stringify(mileage));
    formData.append("mileage", mileage);
    formData.append("transmission", transmission);
    formData.append("wav_type", wav_type);
    formData.append("space", space);
    formData.append("title", title);
    formData.append("stock_id", stock_id.replace(/\s/g, ""));
    formData.append("fuel_type", fuel_type);
    formData.append("exterior_colour", exterior_colour);
    formData.append("seating", seating);
    formData.append("wheelchairs", wheelchairs);
    formData.append("conversion", conversion);
    formData.append("status", status);
    formData.append("description", description);
    name !== "adding" &&
      formData.append("deletedImages", JSON.stringify(deletedImages));
    const selectedFeatures = features.filter(
      (feature) => feature.selected === true
    );
    const featuresArray = [];
    for (let feature of selectedFeatures) {
      featuresArray.push(feature.title);
    }
    selectedFeatures &&
      selectedFeatures.length > 0 &&
      formData.append("features", JSON.stringify(featuresArray));
    const mainUrl =
      type === "create" ? `${url}/wav/create` : `${url}/wav/${id}`;
    const method = type === "create" ? "POST" : "PATCH";


    try {
      const response = await fetch(mainUrl, {
        method,
        body: formData,
        headers: {
          authorization: `${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSucces(true);
        dispatch(getWavs());
        dispatch(getDealer(user._id));
        getUser();
        setDeletedImages([]);
        setTimeout(() => {
          setSucces(false);
          name === "adding" ? setAdding(false) : setEditing(false);
        }, 3000);

        setSavingData(false)
      } else {
        const data = await response.json();
        console.log("error data: ", data);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
        setSavingData(false)

      }
    } catch (err) {
      console.log(err);
    }
  };

  const selectFeature = (id) => {
    let updatedFeatures = features.map((ele) => {
      if (ele._id == id) {
        ele.selected = !ele.selected
      }
      return ele
    })
    // const feature = features.filter((feature) => feature._id === id);
    // feature[0].selected
    //   ? (feature[0].selected = false)
    //   : (feature[0].selected = true);

    // const updatedFeatures = features.filter((feature) => id !== feature._id);
    // updatedFeatures.push(feature[0]);
    setFeatures(updatedFeatures);
  };

  const selectFeature_old = (id) => {
    const feature = features.filter((feature) => feature._id === id);
    feature[0].selected
      ? (feature[0].selected = false)
      : (feature[0].selected = true);

    const updatedFeatures = features.filter((feature) => id !== feature._id);
    updatedFeatures.push(feature[0]);
    setFeatures(updatedFeatures);
  };
  const handleSubmit = (e, id) => {
    e.preventDefault();
    name === "adding" ? doWav("create", isDraft) : doWav("update", isDraft, id);
  };
  const handleFlagged = (image) => {
    const imageExists =
      deletedImages.length > 0 && deletedImages.find((img) => img === image);
    setFlagged(true);
  };
  const handleImage = (image) => {
    setDeletedImages([...deletedImages, image]);
  };

  useEffect(() => {
    let filteredModelsList = (modelsList || []).filter((ele) => ele.make_id.title.toLowerCase().trim() == make.toLowerCase().trim());
    setFilteredModelsList(filteredModelsList);
    // setModels("")
  }, [make, modelsList])

  console.log("FROM WAV", "make", make)
  console.log("FROM WAV", "models", models)

  // console.log('featured image', featuredImage)
  // console.log('selectedFeaturedImage', selectedFeaturedImage)

  return (
    <div className="choose-photos">
      <div className="go-back text-left">
        <button
          onClick={() => {
            name === "adding" ? setAdding(false) : setEditing(false);
          }}
          className="btn btn-primary"
        >
          Go Back
        </button>
      </div>

      <div className="choose-photos-header mb-3">
        <h3>Choose Photos</h3>
        {/* {exisitingImages && exisitingImages.length > 0 && (
              <div>
                <small className="text-muted">
                  Click on the photo you would like to remove.
                </small>
              </div>
            )} */}
      </div>
      {/* <div className="row photos">
            {exisitingImages &&
              exisitingImages.length > 0 &&
              exisitingImages.map((image) => (
                <div key={image} className="col-sm-6 col-md-4 mt-4">
                  <div
                    onClick={(e) => {
                      e.target.classList.add("d-none");
                      handleImage(image);
                    }}
                    className="photo"
                  >
                    <img
                      src={`https://dev.wavcompare.com/uploads/${image}`}
                      alt="car"
                      className="img-fluid"
                    />
                  </div>
                </div>
              ))}
          </div> */}
      <div className=" col-md-12">
        <div className="row">
          <div className="col-md-65 m-auto">
            <div
              className="upload-photos"
              style={{ display: "flex", flexDirection: "column" }}

            >
              <label htmlFor="upload-photo" className="">
                <div className="icon text-center ">
                  <MdPhotoCamera />
                  <div>Choose Photos</div>
                </div>
                {/* <div className="text">
                  <AiOutlineUpload />
                  Upload
                </div> */}
              </label>
              <div>
                <label className="choose_file">
                  <p className="mb-0"><UploadSvg />Upload Image</p>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => {
                      changeHandler(e)
                      setImages(e.target.files)
                    }}
                    id="upload-photo"
                    images={images}
                    style={{
                      display: "block",
                      color: "white"
                    }}
                  />
                </label>
                <p className="text-center mb-1">{imagesarr.length} files</p>
              </div>

            </div>
          </div>
        </div>

        {
          exisitingImages.length > 0 ?
            <div className="row">
              {
                exisitingImages.map((image, idx) => {
                  if (!deletedImages.includes(image)) {
                    return <div className="col-md-3 img_row1" style={{ postion: "relative" }} key={Math.random()} >
                      <div className="img_row_box img_row_box1">
                        {
                          selectedFeaturedImage == image ? (
                            <span style={{ right: "28px" }}><Star style={{ cursor: "pointer" }} /></span>
                          ) : (
                            <span style={{ right: "28px" }}><HollowStar style={{ cursor: "pointer" }} onClick={() => {
                              setSelectedFeatureImage(image)
                              setFeaturedImage("")
                            }} /></span>
                          )
                        }

                        <span><AiOutlineDelete style={{ color: "red", cursor: "pointer" }} onClick={() => handleImage(image)} /></span>
                        <p key={idx}> <img src={`https://dev.wavcompare.com/uploads/${image.replace("images/", "images/thumbnails/")}`} alt="" /> </p>
                      </div>
                    </div>
                  }
                })
              }
            </div> : null
        }

        {
          imagesarr.length > 0 ?
            <div className="row">
              {
                imagesarr.map((image, idx) => {
                  return <div className="col-md-3 img_row1" style={{ postion: "relative" }} key={Math.random()} >
                    <div className="img_row_box img_row_box1">
                      {
                        featuredImage === idx ? (
                          <span style={{ right: "28px" }}><Star style={{ cursor: "pointer" }} /></span>
                        ) : (
                          <span style={{ right: "28px" }}><HollowStar style={{ cursor: "pointer" }} onClick={() => {
                            setSelectedFeatureImage("")
                            setFeaturedImage(idx)
                          }} /></span>
                        )
                      }

                      <span><AiOutlineDelete style={{ color: "red", cursor: "pointer" }} onClick={() => handleDelete(idx)} /></span>
                      <p key={idx}> <img src={image.fileData} alt="" /> </p>
                    </div>
                  </div>
                })
              }
            </div> : null
        }
      </div>


      <div className="vehicle-details mt-4">
        <h3>Vehicle Details</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            name === "adding" ? doWav("create", isDraft) : doWav("update", isDraft, wav._id);
          }}
          className="row"
        >
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="title">
                Title <span className="text-danger">*</span>
              </label>
              <input
                value={title}
                required
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                className="form-control"
                id="title"
                placeholder="Title"
                aria-describedby="title"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="body-type">
                Body Type <span className="text-danger">*</span>
              </label>
              <select
                value={body}
                required
                onChange={(e) => setBody(e.target.value)}
                className="form-control"
                id="body-type"
              >
                <option value="N/A">N/A</option>
                <option value="Estate">Estate</option>
                <option value="Minibus">Minibus</option>
                <option value="MPV">MPV</option>
                <option value="Van">Van</option>
                <option value="Suv">SUV</option>
                <option value="Hatchback">Hatchback</option>
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="condition">
                Condition <span className="text-danger">*</span>
              </label>
              <select
                value={condition}
                required
                onChange={(e) => setCondtion(e.target.value)}
                className="form-control"
                id="condition"
              >
                <option value="N/A">N/A</option>
                <option value="used">Used</option>
                <option value="new">New</option>
                <option value="motability">Motability</option>
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="conversion">
                Conversion <span className="text-danger">*</span>
              </label>
              <select
                value={conversion}
                onChange={(e) => setConversion(e.target.value)}
                className="form-control"
                id="conversion"
              >
                <option value="N/A">N/A</option>
                <option value="Allied Mobility">Allied Mobility</option>
                <option value="Atlas">Atlas</option>
                <option value="Automative Group">Automative Group</option>
                <option value="Bekker">Bekker</option>
                <option value="Bristol Street Versa">
                  Bristol Street Versa
                </option>
                <option value="Brook Miller">Brook Miller</option>
                <option value="Brotherwood">Brotherwood</option>
                <option value="Constables">Constables</option>
                <option value="Gleneagles">Gleneagles</option>
                <option value="GM Coachwork">GM Coachwork</option>
                <option value="Gowrings">Gowrings</option>
                <option value="Jubilee Automotive">Jubilee Automotive</option>
                <option value="Jubilee Renault Tech Liberty">
                  Jubilee Renault Tech Liberty
                </option>
                <option value="Lateral Coversion">Lateral Coversion</option>
                <option value="Lewis Reed">Lewis Reed</option>
                <option value="McElmeel">McElmeel</option>
                <option value="Motability">Motability</option>
                <option value="O&H">O&H</option>
                <option value="Renault Tech">Renault Tech</option>
                <option value="Sirus Automotive">Sirus Automotive</option>
                <option value="Sirus Switch">Sirus Switch</option>
                <option value="Taxi and Bus Conversions">
                  Taxi and Bus Conversions
                </option>
                <option value="TBC Conversion">TBC Conversion</option>
                <option value="Versa">Versa</option>
                <option value="Wilson Healy">Wilson Healy</option>
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="engine">
                Engine <span className="text-danger">*</span>
              </label>
              <input
                value={engine}
                required
                onChange={(e) => setEngine(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
                id="engine"
                placeholder="Engine Size"
                aria-describedby="engine"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="entrance">
                Entrance <span className="text-danger">*</span>
              </label>
              <select
                value={entrance}
                required
                onChange={(e) => setEntrance(e.target.value)}
                className="form-control"
                id="entrance"
              >
                <option value="N/A">N/A</option>
                <option defaultChecked value="Auto Side Step">
                  Auto Side Step
                </option>
                <option value="Manual Rear Ramp">Manual Rear Ramp</option>
                <option value="Powered Rear Lift">Manual Side Ramp</option>
                <option value="Powered Rear Ramp">Powered Rear Lift</option>
                <option value="Ride Up Front">Powered Side Lift</option>\
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="color">
                Exterior Colour <span className="text-danger">*</span>
              </label>
              <input
                value={exterior_colour}
                required
                onChange={(e) => setColor(e.target.value)}
                type="text"
                className="form-control"
                id="color"
                placeholder="Color"
                aria-describedby="color"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="fuel-type">
                Fuel Type <span className="text-danger">*</span>
              </label>
              <select
                value={fuel_type}
                onChange={(e) => setFuelType(e.target.value)}
                className="form-control"
                id="fuel-type"
              >
                <option value="N/A">N/A</option>
                <option value="LPG">LPG</option>
                <option value="Petrol">Petrol</option>
                <option value="Diesel">Diesel</option>
                <option value="Electric">Electric</option>
                <option value="Hybrid">Hybrid</option>
              </select>
            </div>
          </div>

          {/* <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="make">
                Make <span className="text-danger">*</span>
              </label>
              <input
                value={make}
                required
                onChange={(e) => setMake(e.target.value)}
                type="text"
                className="form-control"
                id="make"
                placeholder="Make"
                aria-describedby="make"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="model">
                Model <span className="text-danger">*</span>
              </label>
              <input
                value={models}
                required
                onChange={(e) => setModels(e.target.value)}
                type="text"
                className="form-control"
                id="model"
                placeholder="Model"
                aria-describedby="model"
              />
            </div>
          </div> */}


          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="make">
                Make <span className="text-danger">*</span>
              </label>
              <select
                value={make}
                required
                onChange={(e) => {
                  setMake(e.target.value);

                  setModels("")
                }}
                type="text"
                className="form-control"
                id="make"
                placeholder="Make"
                aria-describedby="make"
              >
                <option key={Math.random().toString()} value={""}>Select Make</option>
                {
                  (makesList || []).map((ele, index) => {
                    return (
                      <option key={index} value={ele.title.trim().toLowerCase()} >{ele.title}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="model">
                Model <span className="text-danger">*</span>
              </label>
              <select
                value={models}
                required
                onChange={(e) => setModels(e.target.value)}
                type="text"
                className="form-control"
                id="model"
                placeholder="Model"
                aria-describedby="model"
              >
                {make && make != "" ? <option key={Math.random().toString()} value={""}>Select Model</option> : <option key={Math.random().toString()} value={""}>Select Make First</option>}
                {
                  (filteredModelsList || []).map((ele, index) => {
                    return (
                      <option key={index} value={ele.title.trim().toLowerCase()} >{ele.title}</option>
                    )
                  })
                }

              </select>
            </div>
          </div>


          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="mileage">
                Mileage <span className="text-danger">*</span>
              </label>
              <input
                value={mileage}
                required
                onChange={(e) => setMileage(e.target.value)}
                type="number"
                className="form-control"
                id="mileage"
                placeholder="Mileage"
                aria-describedby="mileage"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="price">
                Price <span className="text-danger">*</span>
              </label>
              <input
                value={price}
                required
                onChange={(e) => setPrice(e.target.value)}
                type="text"
                className="form-control"
                id="price"
                placeholder="Price"
                aria-describedby="price"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="year">
                Year of Production{" "}
                <span className="text-danger">
                  *
                </span>
              </label>
              <select
                value={year}
                required
                onChange={(e) => setYear(e.target.value)}
                className="form-control"
                id="year"
              >
                <option value="N/A">N/A</option>
                {years}
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="transmission">
                Transmission <span className="text-danger">*</span>
              </label>
              <select
                value={transmission}
                onChange={(e) => setTransmission(e.target.value)}
                className="form-control"
                id="transmission"
              >
                <option value="N/A">N/A</option>
                <option value="Automatic">Automatic</option>
                <option value="Automatic; Manual">Automatic; Manual</option>
                <option value="DSG">DSG</option>
                <option value="Manual">Manual</option>
                <option value="Semi Automatic">Semi Automatic</option>
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="type">
                Type <span className="text-danger">*</span>
              </label>
              <select
                value={wav_type}
                required
                onChange={(e) => setWavType(e.target.value)}
                className="form-control"
                id="type"
              >
                <option value="Rear Passenger">Rear Passenger</option>
                <option value="Drive From Wheelchair">
                  Drive From Wheelchair
                </option>
                <option value="Ride Up Front">Ride Up Front</option>
                <option value="Transfer Seat">Transfer Seat</option>
              </select>
            </div>
          </div>
          {
            seating == "" || (/^\d+$/.test(seating)) ? (
              <div className="col-sm-12 col-md-6">
                <label htmlFor="seating">
                  Number of Seatings And Wheelchairs <span className="text-danger">*</span>
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">

                      <input
                        value={seating}
                        required
                        onChange={(e) => {
                          if (/^\d*$/.test(e.target.value)) {
                            setSeating(e.target.value);
                          }
                        }}
                        type="number"
                        className="form-control"
                        id="seating"
                        placeholder="Number Of Seats"
                        aria-describedby="seating"
                        step={1}
                        min={1}
                      />{" "}
                    </div>
                  </div>
                  <div className="col-md-6 pl-0">
                    <div className="form-group">

                      <input
                        value={wheelchairs}
                        required
                        onChange={(e) => {
                          if (/^\d*$/.test(e.target.value)) {
                            setWheelchairs(e.target.value);
                          }
                        }}
                        type="number"
                        className="form-control"
                        id="wheelchairs"
                        placeholder="Number Of Wheelchairs"
                        aria-describedby="wheelchairs"
                        step={1}
                        min={1}
                      />{" "}
                    </div>
                  </div>
                </div>

              </div>
            ) : (
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="seating">
                    Seating <span className="text-danger">*</span>
                  </label>
                  <input
                    value={seating}
                    required
                    onChange={(e) => setSeating(e.target.value)}
                    type="text"
                    className="form-control"
                    id="seating"
                    placeholder="Seating"
                    aria-describedby="seating"
                  />{" "}
                </div>
              </div>
            )

          }

          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="size">
                Size <span className="text-danger">*</span>
              </label>
              <select
                value={size}
                required
                onChange={(e) => setSize(e.target.value)}
                className="form-control"
                id="size"
              >
                <option value="N/A">N/A</option>
                <option value="Large">Large</option>
                <option value="Medium">Medium</option>
                <option value="Small">Small</option>
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="space">
                Space <span className="text-danger">*</span>
              </label>
              <select
                value={space}
                required
                onChange={(e) => setSpace(e.target.value)}
                className="form-control"
                id="space"
              >
                <option value="N/A">N/A</option>
                <option value="Lowered Floor">Lowered Floor</option>
                <option value="Raised Roof"> Raised Roof</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          {/* <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="status">
                Status <span className="text-danger">*</span>
              </label>
              <select
                value={status}
                required
                onChange={(e) => setStatus(e.target.value)}
                className="form-control"
                id="status"
              >
                <option value="active">Active</option>
                <option value="ended">Closed</option>
              </select>
            </div>
          </div> */}
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="stockid">
                Vehicle Reg. No. <span className="text-danger">*</span>
              </label>
              <input
                value={stock_id}
                required
                onChange={(e) => setStockId(e.target.value)}
                type="text"
                className="form-control"
                id="stockid"
                placeholder="Vehicle Reg. No."
                aria-describedby="stockid"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="doors">
                No. Doors <span className="text-danger">*</span>
              </label>
              <select
                value={doors}
                required
                onChange={(e) => setDoors(e.target.value)}
                className="form-control"
                id="doors"
              >
                <option value="N/A">N/A</option>
                <option value="1 door">1 door</option>
                <option value="2 doors">2 doors</option>
                <option value="3 doors">3 doors</option>
                <option value="4 doors">4 doors</option>
                <option value="5 doors">5 doors</option>
                <option value="6 doors">6 doors</option>
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-12">
            <div className="form-group">
              <label htmlFor="description">
                Description <span className="text-danger">*</span>
              </label>
              <textarea
                value={description}
                required
                onChange={(e) => setDescription(e.target.value)}
                className="form-control"
                id="description"
                rows="5"
              ></textarea>
            </div>
          </div>
          {/* <div className="choose-photos-header mb-3">
            <h3>Choose Photos</h3> */}
          {/* {exisitingImages && exisitingImages.length > 0 && (
              <div>
                <small className="text-muted">
                  Click on the photo you would like to remove.
                </small>
              </div>
            )} */}
          {/* </div> */}
          {/* <div className="row photos">
            {exisitingImages &&
              exisitingImages.length > 0 &&
              exisitingImages.map((image) => (
                <div key={image} className="col-sm-6 col-md-4 mt-4">
                  <div
                    onClick={(e) => {
                      e.target.classList.add("d-none");
                      handleImage(image);
                    }}
                    className="photo"
                  >
                    <img
                      src={`https://dev.wavcompare.com/uploads/${image}`}
                      alt="car"
                      className="img-fluid"
                    />
                  </div>
                </div>
              ))}
          </div> */}
          {/* <div className=" col-md-12">
            <div className="row">
              <div className="col-md-65 m-auto">
                <div
                  className="upload-photos"
                  style={{ display: "flex", flexDirection: "column" }}

                >
                  <label htmlFor="upload-photo" className="">
                    <div className="icon text-center ">
                      <MdPhotoCamera />
                      <div>Choose Photos</div>
                    </div> */}
          {/* <div className="text">
                  <AiOutlineUpload />
                  Upload
                </div> */}
          {/* </label>
                  <div>
                    <label className="choose_file">
                      <p className="mb-0">Upload Image</p>
                      <input
                        type="file"
                        multiple
                        onChange={(e) => {
                          changeHandler(e)
                          setImages(e.target.files)
                        }}
                        id="upload-photo"
                        images={images}
                        style={{
                          display: "block",
                          color: "white"
                        }}
                      />
                    </label>
                    <p className="text-center mb-1">{imagesarr.length} files</p>
                  </div>

                </div>
              </div>
            </div>

            {
              exisitingImages.length > 0 ?
                <div className="row">
                  {
                    exisitingImages.map((image, idx) => {
                      if (!deletedImages.includes(image)) {
                        return <div className="col-md-3 img_row1" style={{ postion: "relative" }} key={Math.random()} >
                          <div className="img_row_box img_row_box1">
                            {
                              selectedFeaturedImage == image ? (
                                <span style={{ right: "28px" }}><Star style={{ cursor: "pointer" }} /></span>
                              ) : (
                                <span style={{ right: "28px" }}><HollowStar style={{ cursor: "pointer" }} onClick={() => {
                                  setSelectedFeatureImage(image)
                                  setFeaturedImage("")
                                }} /></span>
                              )
                            }

                            <span><AiOutlineDelete style={{ color: "red", cursor: "pointer" }} onClick={() => handleImage(image)} /></span>
                            <p key={idx}> <img src={`https://dev.wavcompare.com/uploads/${image}`} alt="" /> </p>
                          </div>
                        </div>
                      }
                    })
                  }
                </div> : null
            }

            {
              imagesarr.length > 0 ?
                <div className="row">
                  {
                    imagesarr.map((image, idx) => {
                      return <div className="col-md-3 img_row1" style={{ postion: "relative" }} key={Math.random()} >
                        <div className="img_row_box img_row_box1">
                          {
                            featuredImage === idx ? (
                              <span style={{ right: "28px" }}><Star style={{ cursor: "pointer" }} /></span>
                            ) : (
                              <span style={{ right: "28px" }}><HollowStar style={{ cursor: "pointer" }} onClick={() => {
                                setSelectedFeatureImage("")
                                setFeaturedImage(idx)
                              }} /></span>
                            )
                          }

                          <span><AiOutlineDelete style={{ color: "red", cursor: "pointer" }} onClick={() => handleDelete(idx)} /></span>
                          <p key={idx}> <img src={image.fileData} alt="" /> </p>
                        </div>
                      </div>
                    })
                  }
                </div> : null
            }
          </div> */}

          <Features features={features} selectFeature={selectFeature} />
          {/* <div className="select-features">
            <h3>Select Vehicle Features</h3>
            <div className="features">
              {features &&
                features.length > 0 &&
                features.sort().map((feature) => {
                  return (
                    <button
                      onClick={() => selectFeature(feature._id)}
                      className={feature.selected ? "feature-selected" : null}
                      id={feature._id}
                      type="button"
                      key={feature._id}
                    >
                      {feature.title}
                    </button>
                  );
                })}
            </div>
          </div> */}
          <div className="btns">
            {/* <div>
              {name === "adding" && (
                <button>
                  <AiFillEye />
                  Preview Listing
                </button>
              )}
            </div> */}
            <div>
              {name === "adding" ? (
                <div className="d-flex">
                  <button
                    className="mx-1"
                    style={{ backgroundColor: "brown" }}
                    type="submit"
                    onClick={() => {
                      setIsDraft(1)
                    }}
                    disabled={savingData}

                  >
                    <AiFillCar />
                    Save As Draft (1 Credit)
                  </button>

                  <button
                    className="mx-1"
                    disabled={coinError || savingData}
                    style={coinError ? { backgroundColor: "grey" } : {}}
                    onClick={() => {
                      setIsDraft(0)
                    }}

                    type="submit"
                  >
                    <AiFillCar />
                    Publish (1 Credit)
                  </button>
                </div>

              ) : (
                <div className="d-flex">
                  <button
                    style={{ backgroundColor: "brown" }}
                    type="submit"
                    onClick={() => {
                      setIsDraft(1)
                    }}
                    disabled={savingData}
                  >
                    <AiFillCar />
                    Save As Draft
                  </button>


                  <button type="submit" disabled={savingData} onClick={() => {
                    console.log("UPDATE")
                  }}>
                    <AiFillCar />
                    Update
                  </button>
                </div>
              )}
              <p className="text-center">
                {name === "adding" &&
                  ` You have ${dealerFromState.coins} credits remaining`}
              </p>
            </div>
          </div>
        </form>

        {success && (
          <div className="alert alert-success mt-3" role="alert">
            {name === "adding"
              ? "Wav has been created successfully"
              : "Wav has been edited successfully"}
          </div>
        )}
        {error && (
          <div className="alert alert-danger mt-3" role="alert">
            {name === "adding"
              ? "Something went wrong. Please fill in all the required details"
              : "Something went wrong. Please fill in all the required details"}
          </div>
        )}
        {name === "adding" && coinError && (
          <div className="alert alert-danger mt-3" role="alert">
            You do not have enough credits to make an ad. Please fill your
            charge
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                window.open("/sell-your-wav-private-seller", "_blank");
                // history.push("/sell-your-wav-private-seller")
              }}
            >
              {" "}
              here
            </span>
            .
          </div>
        )}

        {
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        }
      </div>
    </div>
  );
};

export default AddOrEdit;
