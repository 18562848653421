import React from "react";

const ProductWavDetails = ({ wav }) => {
  return (
    <div>
      <h6>WAV DETAILS</h6>
      <div className="product-card-wav-details">
        <div className="product-card-wav-detail">
          <ul>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/car_64px.png" alt="logo" />
                </div> */}
                <div className="text">Body</div>
                <div className="normal-text">{wav.body}</div>
              </div>
            </li>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/car-engine_64px.png" alt="logo" />
                </div> */}
                <div className="text">Engine</div>
                <div className="normal-text">{wav.engine}</div>
              </div>
            </li>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/disabled_64px.png" alt="logo" />
                </div> */}
                <div className="text">Size</div>
                <div className="normal-text">{wav.size}</div>{" "}
              </div>
            </li>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/disabled_64px.png" alt="logo" />
                </div> */}
                <div className="text">Entrance</div>
                <div
                  className="normal-text"
                  // style={
                  //   wav.entrance && wav.entrance.length > 10
                  //     ? { fontSize: "10px" }
                  //     : null
                  // }
                >
                  {wav.entrance}
                </div>
              </div>
            </li>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/car_64px.png" alt="logo" />
                </div> */}
                <div className="text">Doors</div>
                <div className="normal-text">{wav.doors}</div>
              </div>
            </li>
          </ul>
        </div>
        <div className="product-card-wav-detail">
          <ul>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img
                    src="/assets/road-with-broken-line_64px.png"
                    alt="logo"
                  />
                </div> */}
                <div className="text">Mileage</div>
                <div className="normal-text">{wav.mileage}</div>
              </div>
            </li>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/manual-transmission_64px.png" alt="logo" />
                </div> */}
                <div className="text">Transmission</div>
                <div className="normal-text">{wav.transmission}</div>
              </div>
            </li>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/disabled_64px.png" alt="logo" />
                </div> */}
                <div className="text">Wav Type</div>
                <div className="normal-text">{wav.wav_type}</div>
              </div>
            </li>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/disabled_64px.png" alt="logo" />
                </div> */}
                <div className="text">Space</div>
                <div className="normal-text">{wav.space}</div>
              </div>
            </li>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/hashtag_64px.png" alt="logo" />
                </div> */}
                <div className="text">Stock ID</div>
                <div className="normal-text">{wav.stock_id}</div>
              </div>
            </li>
          </ul>
        </div>
        <div className="product-card-wav-detail">
          <ul>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/gas-station_64px.png" alt="logo" />
                </div> */}
                <div className="text">Fuel Type</div>
                <div className="normal-text">{wav.fuel_type}</div>
              </div>
            </li>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/star_64px.png" alt="logo" />
                </div> */}
                <div className="text">Exterior Col...</div>
                <div className="normal-text">{wav.exterior_colour}</div>
              </div>
            </li>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/disabled_64px.png" alt="logo" />
                </div> */}
                <div className="text">Seatings</div>
                <div className="normal-text">{wav.seating}</div>
              </div>
            </li>
            <li>
              <div className="bold">
                {/* <div className="icon">
                  <img src="/assets/disabled_64px.png" alt="logo" />
                </div> */}
                <div className="text">Conversion</div>
                <div
                  className="normal-text"
                  // style={
                  //   wav.entrance && wav.entrance.length > 10
                  //     ? { fontSize: "10px" }
                  //     : null
                  // }
                >
                  {wav.conversion}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductWavDetails;
