import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import MainNavbar from "./Components/Navbar/MainNavbar";
import HomePage from "./Components/HomePage/HomePage";
import Footer from "./Components/Footer/Footer";
import SearchResults from "./Components/Search/SearchResults";
import ProductPage from "./Components/Product/ProductPage";
import Dealers from "./Components/Dealers/Dealers";
import DealerPage from "./Components/Dealers/DealerPage";
import HelpAndAdvice from "./Components/HelpAndAdvice/HelpAndAdvice";
import SellYourWav from "./Components/Sell/SellYourWav";
import Hire from "./Components/Hire/Hire";
import ContactUs from "./Components/ContactUs/ContactUs";
import SignUpSignIn from "./Components/SignUpSignIn/SignUpSignIn";
import Dmuk from "./Components/DMUK/Dmuk";
import CancelAds from "./Components/CancelAds/CancelAds";
import MiniBus from "./Components/MiniBus/MiniBus";
import Profile from "./Components/Profile/Profile";
import SearchAndBuy from "./Components/Search/SearchAndBuy";
import Admin from "./Components/Admin/Admin";
import { useDispatch, useSelector } from "react-redux";
import ProtectedProfile from "./Components/Protected/ProtectedProfile";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { CHECK_LOGGED_IN_USER } from "./redux/constants/constants";
import ProtectedSigninSignup from "./Components/Protected/ProtectedSigninSignup";

import { selectComponent, toggleModal } from "./redux/actions/dealerActions";
import withHelmet from "./hoc/withHelmet";
import NotFoundPage from "./Components/NotFoundPage";
import Layout from "./Layout";
import { RotatingLines } from "react-loader-spinner";

function App() {
  const auth = useSelector((state) => state.dealers.authenticated);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const jwt = cookies.get("jwt");
  const user = cookies.get("user");

  function clearUserAuthDetails() {
    // Clear Redux state (dispatch your action here)
    // dispatch({ type: 'RESET_STATE' });

    dispatch({ type: "RESET_AUTH_STORE" });
    cookies.remove("jwt");
    cookies.remove("user");
    // cookies.clear()
    // Clear local storage
    localStorage.clear();

    // // Clear cookies (if applicable)
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // // Force a hard refresh to clear cache
    window.location.reload(true);
    window.location.href = window.location.origin;
  }

  useEffect(() => {
    let lastVisitTime = localStorage.getItem("lastVisitTime");

    const currentTime = new Date().getTime();
    if (lastVisitTime) {
      lastVisitTime = +lastVisitTime;
      const timeDifference = currentTime - lastVisitTime;
      console.log("timeDifference", timeDifference);

      // Check if 24 hours have passed
      if (timeDifference > 24 * 60 * 60 * 1000) {
        clearUserAuthDetails();
      } else {
        // Update last visit time in local storage
        localStorage.setItem("lastVisitTime", currentTime);
      }
    } else {
      // clearUserAuthDetails();

      localStorage.setItem("lastVisitTime", currentTime);
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  useEffect(() => {
    if (jwt && user) {
      dispatch({ type: CHECK_LOGGED_IN_USER, payload: user });
    }
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const state = query.get("state");
    const token = query.get("token");
    if (state) {
      if (state == "valid") {
        dispatch(toggleModal(true));
        dispatch(selectComponent("change_password"));
      } else {
        dispatch(toggleModal(true));
        dispatch(selectComponent("reset_password"));
      }
    }
  }, []);

  // useEffect(() => {
  //   let urlArr = window.location.pathname.split("/")
  //   console.log("urlArr", urlArr)

  //   if (urlArr[1] == 'reset-password') {
  //     dispatch(selectComponent("change_password"))
  //     console.log("RESET PASSWORD")
  //     dispatch(toggleModal(true))
  //   }
  // }, [])
  const HomePageWithHelmet = withHelmet(
    HomePage,
    "Find Your Next Wheelchair Accessible Vehicle",
    "Choose from over 500 WAVs to buy or hire from local specialist dealers and private sellers across the UK. New & Used. Manual & Automatic. Compare now!"
  );

  const SearchAndBuyWithHelmet = withHelmet(
    SearchAndBuy,
    "Wavs for Sale - Browse Over 500 New & Used Wavs Near You",
    "Discover the disabled adapted vehicle best suited to your needs. Small cars, large vans, and even minibuses! Find the best deal now."
  );

  const SellYourWavWithHelmet = withHelmet(
    SellYourWav,
    "Sell Your Wheelchair Accessible Vehicle in 3 Easy Steps",
    "With one of the biggest UK marketplaces, sell your WAV from as little as £7.50. Packages available for dealers. Claim your 7 day free trial today."
  );

  const DealersWithHelmet = withHelmet(
    Dealers,
    "Find a Wav Dealer Near You | Your UK Wav Marketplace",
    "Explore the best WAV dealers in your area. Browse their quality new & used vehicles. Read reviews from other buyers. Find your next WAV today."
  );

  const HelpAndAdviceWithHelmet = withHelmet(
    HelpAndAdvice,
    "Wav Help & Advice From the Marketplace That Knows Them Best",
    "Got a question about wheelchair accessible vehicles? Get the answer from the experts by reaching out or reading our guides."
  );

  const MiniBusWithHelmet = withHelmet(
    MiniBus,
    "Wheelchair Accessible Minibus - Carry Up to 3 Wheelchairs!",
    "Carry 3 wheelchairs and 9 passengers with these wheelchair access minibuses. New & Used available. Call 0800 776 5036 to get a quote."
  );

  const DmukWithHelmet = withHelmet(
    Dmuk,
    "Disabled Motoring UK - Charity for Disabled Drivers",
    "Through WAV Compare, our Information Officers can help with more complex questions and in depth knowledge regarding disabled motoring."
  );

  const ContactUsWithHelmet = withHelmet(
    ContactUs,
    "Got a question about WAVs? Our experts can help.",
    "Whether you're confused about what type of WAV you need or you're looking to lease a minibus, our WAV experts are here to help. Send us a message!"
  );


  const ExternalRedirect = () => {
    React.useEffect(() => {
      window.location.href = 'https://hire.wavcompare.com/';
    }, []);

    return <div id="wrapper">
      <div id="info">
        <RotatingLines
          height="96"
          width="96"
          strokeWidth="5"
          strokeColor="#004f63"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </div>;
  };

  return (
    <div className="App">
      <div className="">
        {/* <MainNavbar />
        <Navbar1 /> */}
        <Router>
          <Switch>
            {/* <Route path="/" component={MainNavbar} /> */}

            <Route exact path="/">
              {/* <HomePage /> */}
              {/* <HomePageWithHelmet /> */}
              <Layout>
                <HomePageWithHelmet />
              </Layout>
            </Route>

            <Route exact path="/buy">
              {/* <SearchAndBuy name="buy" /> */}
              {/* <SearchAndBuyWithHelmet name="buy" /> */}

              <Layout>
                <SearchAndBuyWithHelmet name="buy" />
              </Layout>
            </Route>

            <Route exact path="/search">
              {/* <SearchResults name="search" /> */}
              <Layout>
                <SearchResults name="search" />
              </Layout>
            </Route>

            {/* <Route exact path="/admin/:id" component={Admin} /> */}
            {/* <Route exact path="/wavs/:id" component={ProductPage} /> */}
            <Route exact path="/wavs/:id">
              <Layout>
                <ProductPage />
              </Layout>
            </Route>
            {/* <Route
            exact
            path="/dealers"
            // component={Dealers}
            component={DealersWithHelmet}
          /> */}

            <Route
              exact
              path="/dealers"
            // component={Dealers}
            // component={DealersWithHelmet}
            >
              <Layout>
                <DealersWithHelmet />
              </Layout>
            </Route>

            {/* <Route
            exact
            path="/help"
            // component={HelpAndAdvice}
            component={HelpAndAdviceWithHelmet}
          /> */}

            <Route
              exact
              path="/help"
            // component={HelpAndAdvice}
            // component={HelpAndAdviceWithHelmet}
            >
              <Layout>
                <HelpAndAdviceWithHelmet />
              </Layout>
            </Route>

            {/* <Route exact path="/dealers/:id" component={DealerPage} /> */}
            <Route exact path="/dealers/:id">
              <Layout>
                <DealerPage />
              </Layout>
            </Route>

            {/* <Route
            exact
            path="/sell-your-wav-private-seller"
            // component={SellYourWav}
            component={SellYourWavWithHelmet}
          /> */}

            <Route
              exact
              path="/sell-your-wav-private-seller"
            // component={SellYourWav}
            // component={SellYourWavWithHelmet}
            >
              <Layout>
                <SellYourWavWithHelmet />
              </Layout>
            </Route>

            {/* <Route exact path="/hire" component={Hire} /> */}
            {/* <Route
            exact
            path="/contact-us"
            // component={ContactUs}
            component={ContactUsWithHelmet}
          /> */}

            <Route
              exact
              path="/contact-us"
            // component={ContactUs}
            // component={ContactUsWithHelmet}
            >
              <Layout>
                <ContactUsWithHelmet />
              </Layout>
            </Route>

            {/* <ProtectedSigninSignup exact path="/signup">
            <SignUpSignIn />
          </ProtectedSigninSignup> */}

            <ProtectedSigninSignup exact path="/signup">
              <Layout>
                <SignUpSignIn />
              </Layout>
            </ProtectedSigninSignup>

            {/* <Route
            exact
            path="/dmuk"
            // component={Dmuk}
            component={DmukWithHelmet}
          /> */}

            <Route
              exact
              path="/dmuk"
            // component={Dmuk}
            // component={DmukWithHelmet}
            >
              <Layout>
                <DmukWithHelmet />
              </Layout>
            </Route>

            {/* <Route exact path="/cancel-ads" component={CancelAds} /> */}
            <Route exact path="/cancel-ads">
              <Layout>
                <CancelAds />
              </Layout>
            </Route>

            {/* <Route
            exact
            path="/wheelchair-accessible-minibus"
            // component={MiniBus}
            component={MiniBusWithHelmet}
          /> */}

            <Route
              exact
              path="/wheelchair-accessible-minibus"
            // component={MiniBus}
            // component={MiniBusWithHelmet}
            >
              <Layout>
                <MiniBusWithHelmet />
              </Layout>
            </Route>

            {/* <ProtectedProfile exact path="/profile/:id">
            <Profile />
          </ProtectedProfile> */}

            <ProtectedProfile exact path="/profile/:id">
              <Layout>
                <Profile />
              </Layout>
            </ProtectedProfile>

            <Route exact path="/hire" component={ExternalRedirect} />

            <Route exact path="/dealers-list/">
              <Redirect to={{ pathname: '/dealers' }} />
            </Route>

            {/* <Route path="/" component={Footer} /> */}
            <Route exact path="*">
              <Layout>
                <NotFoundPage />
              </Layout>
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
