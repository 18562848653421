import React from "react";
import { FaRegCalendarAlt, FaEdit, FaRegCommentDots } from "react-icons/fa";
const MiniBusHeader = () => {
  return (
    <div className="minibus-header">
      <div className="wrapper">
        <div className="text-wrapper">
          <h1>Wheelchair Accessible Minibus</h1>
          <div className="options">
            <div className="option">
              <FaRegCalendarAlt /> March 4, 2020
            </div>
            <div className="option">
              <FaEdit /> Posted by: WAVCompare
            </div>
            <div className="option">
              <FaRegCommentDots /> No Comments
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniBusHeader;
