import React from "react";
import { AiFillCar } from "react-icons/ai";

const HelpAndAdviceWheelchairKinds = () => {
  return (
    <div className="help-and-advice-wheelchair-kinds container">
      <h2>What kinds of wheelchair accessible vehicles are there?</h2>
      <div className="subtitle">There are three main types of WAVs.</div>
      <div className="row">
        <div className="col-sm-12 col-md-4 car-kind">
          <div className="car-img">
            <img src="assets/passenger.png" alt="car" />
          </div>
          <div className="description">
            <span className="text-danger">Passenger WAVs</span>. In this type of
            vehicle, the wheelchair user is a rear travelling passenger. They
            enter the vehicle in their wheelchair using a ramp/tail lift,
            through a rear or side door. The wheelchair is then secured in place
            using wheelchair restraints.
          </div>
        </div>
        <div className="col-sm-12 col-md-4 car-kind">
          <div className="car-img">
            <img src="assets/left-side.png" alt="car" />
          </div>
          <div className="description">
            <span className="text-danger">Driver WAVs</span>.This type of WAV
            enable the wheelchair user to drive from their wheelchair. These
            vehicles usually have been modified with adaption controls to meet
            the capabilities of the driver.
          </div>
        </div>
        <div className="col-sm-12 col-md-4 car-kind">
          <div className="car-img">
            <img src="assets/right-side.png" alt="car" />
          </div>
          <div className="description">
            <span className="text-danger">Ride Up Front</span>. This is an
            increasingly popular type of conversion. These WAVs have the
            wheelchair user position ‘up front’ next to the driver in the
            traditional position of a front passenger.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpAndAdviceWheelchairKinds;
