import React, { useState, useEffect } from "react";
import "./navbar.css";
import { GrCompare, GrClose } from "react-icons/gr";
import { FaUserCircle } from "react-icons/fa";
import DropDownMenu from "./DropDownMenu";
import { AiFillCar } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineClose } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { GiPhone } from "react-icons/gi";
import LoginComponent from "../Auth/LoginComponent";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../redux/actions/dealerActions";
import OutsideAlerter from "../../Hooks/OutsideAlerter";



const MainNavbar = () => {
  const { modalOpen } = useSelector((state) => state.dealers);

  const [menuOpen, setMenuOpen] = useState(false);
  const [buyMenu, setBuyMenu] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()
  const cookies = new Cookies();
  const user = cookies.get("user");
  const id = user ? `profile/${user._id}` : "signup";

  if (!location.pathname.endsWith('/')) {
    // Update the pathname to include a slash at the end
    const newPathname = `${location.pathname}/`;
    // Replace the current location with the new pathname
    history.replace({ ...location, pathname: newPathname });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [location]);
  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);
  const homePath = location.pathname === "/";
  const color = homePath ? "transparent" : "#153e4d";

  const handleScroll = () => {
    document.querySelector(".main-nav").style.background = "transparent";
    if (window.scrollY > 20) {
      document.querySelector(".top-nav").classList.add("d-none");
      document.querySelector(".main-nav").style.background = "#153e4d";
      if (document.querySelector(".auth_section_bg")) {
        document.querySelector(".auth_section_bg").style.paddingTop = "0";
      }
      document.body.classList.remove('overflow_hidden');
    } else {
      document.querySelector(".top-nav").classList.remove("d-none");
      document.querySelector(".main-nav").style.background = color;
      if (document.querySelector(".auth_section_bg")) {
        document.querySelector(".auth_section_bg").style.paddingTop = "54px";
      }
    }
  };

  const [navDropdown, setNavDropdown] = useState({
    buy: false,
    sell: false,
    more: false
  })

  const handleDropdown = (e, keyname) => {
    e.preventDefault()
    e.stopPropagation()
    setNavDropdown({ ...navDropdown, ...{ [keyname]: !navDropdown[keyname] } })
  }

  useEffect(() => {
    if (!menuOpen) {
      document.body.classList.remove('overflow_hidden');
    }
    else {
      document.body.classList.add("overflow_hidden")
    }
  }, [menuOpen])

  const handleUserIconClick = () => {
    if (!user) {
      dispatch(toggleModal(!modalOpen));
      // document.body.classList.toggle('overflow_hidden');
    }
    else {
      history.push(`/${id}`);
      setMenuOpen(false);
    }

  }

  return (
    <>
      <div className="fixed-top">
        <div className="top-nav" style={homePath ? { marginBottom: "20px" } : {}}>
          <div className="wrapper d-flex justify-content-between align-items-center px-0">
            <div className="mob_view d-inline-block d-xxl-none d-xl-none d-lg-none d-md-none d-sm-none text-left" onClick={() => {
              history.push(`/`);
              setMenuOpen(false)
            }}>
              {/* <img src="/assets/logo.png" alt="logo" /> */}
            </div>
            <div className="contact-call">
              <a href="tel:0800 832 1795 ">
                <GiPhone /> HELP & ADVICE : 0800 832 1795{" "}
              </a>
              <span>|</span>
              <a href="mailto:HELLO@WAVCOMPARE.COM" className="num2"> HELLO@WAVCOMPARE.COM</a>
            </div>
            <div className="compare-nav">
              <div
                onClick={() => handleUserIconClick()}
                className="login"
              >
                {user ? user.name : "Sign In"}
                <FaUserCircle />
              </div>
              {/* <div
              onClick={() => {
                history.push(`/admin/${user._id}`);
              }}
              className="m-3 login"
              style={{ cursor: "pointer" }}
            >
              Admin
            </div> */}
            </div>
          </div>
        </div>

        <div
          style={
            homePath
              ? {
                background: "transparent",
              }
              : {
                background: "#153e4d",
                boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.53)",
              }
          }
          className="main-nav"
        >
          <div className="flex_menu1"
            style={
              menuOpen
                ? { background: "#153e4d" }
                : {
                  background: "transparent",
                }
            }
          >
            <span>
              {/* <Link to="/" onClick={() => setMenuOpen(false)}><img src="/assets/logo.png" alt="logo" /></Link> */}

              < a href={window.location.origin + `/`} onClick={(event) => event.preventDefault()} ><Link to="/" onClick={() => setMenuOpen(false)}><img src="/assets/logo.png" alt="logo" /></Link></a>


            </span>
            <div
              style={
                menuOpen
                  ? { background: "#153e4d" }
                  : {
                    background: "transparent",
                  }
              }
              className="menu-icon"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? <MdOutlineClose color="#fff" /> : <GiHamburgerMenu />}
            </div>
          </div>
          <div className="wrapper " style={menuOpen ? { display: "block" } : null}>
            <nav className="main-navbar">
              <ul>
                <li id="navbar-title" className="mob_logo_none">
                  {/* <Link
                    onClick={() => setMenuOpen(false)}
                    to="/"
                    className="logo"
                  >
                    <img src="/assets/logo.png" alt="logo" />
                  </Link> */}

                  < a href={window.location.origin + `/`} onClick={(event) => event.preventDefault()} ><Link to="/" onClick={() => setMenuOpen(false)} className="logo" ><img src="/assets/logo.png" alt="logo" /></Link></a>

                </li>
                <li>

                  <li id="buy" className={`${navDropdown.buy ? 'hidedropdown' : ""}`} >
                    {/* <a href="#">
                  <span className="pr-1">BUY</span> <BsChevronDown />
                </a> */}
                    {/* <Link
                      to="/buy" className=""
                      onClick={() => setMenuOpen(false)}
                    >
                      BUY
                      <span className="mob_arrow_span"
                        onClick={(e) => handleDropdown(e, 'buy')}
                      >
                        <i className="fa fa-angle-right mob_arrow1"></i>
                      </span>
                    </Link> */}

                    < a href={window.location.origin + `/buy`} onClick={(event) => event.preventDefault()} >
                      <Link
                        to="/buy" className=""
                        onClick={() => setMenuOpen(false)}
                      >
                        BUY
                        <span className="mob_arrow_span"
                          onClick={(e) => handleDropdown(e, 'buy')}
                        >
                          <i className="fa fa-angle-right mob_arrow1"></i>
                        </span>
                      </Link>
                    </a>

                    <div id="dropdown">
                      {/* <i className="fa fa-times mob_close " ></i> */}
                      <div className="dropdown-buy" >
                        <DropDownMenu name="buy" />
                      </div>
                    </div>
                  </li>
                  <li id="sell" className={`${navDropdown.sell ? 'hidedropdown' : ""}`} >
                    {/* <Link to="/sell-your-wav-private-seller" className="" onClick={() => setMenuOpen(false)}>
                      SELL
                      <span className="mob_arrow_span"
                        onClick={(e) => handleDropdown(e, 'sell')}
                      >
                        <i className="fa fa-angle-right mob_arrow1"></i>
                      </span>
                    </Link> */}

                    < a href={window.location.origin + `/sell-your-wav-private-seller`} onClick={(event) => event.preventDefault()} >
                      <Link
                        to="/sell-your-wav-private-seller" className=""
                        onClick={() => setMenuOpen(false)}
                      >
                        SELL
                        <span className="mob_arrow_span"
                          onClick={(e) => handleDropdown(e, 'sell')}
                        >
                          <i className="fa fa-angle-right mob_arrow1"></i>
                        </span>
                      </Link>
                    </a>

                    <div id="dropdown">
                      <div className="dropdown-buy">
                        <DropDownMenu name="sell" />
                      </div>
                    </div>
                  </li>
                  <li onClick={() => setMenuOpen(false)}>
                    <a href="https://hire.wavcompare.com/" onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation()
                      window.location.href = "https://hire.wavcompare.com/"
                    }}>HIRE</a>
                  </li>
                  <li onClick={() => setMenuOpen(false)}>
                    <a href={window.location.origin + "/dealers"} onClick={(event) => event.preventDefault()} >
                      <Link to="/dealers">
                        DEALERS
                      </Link>
                    </a>
                  </li>
                  <li onClick={() => setMenuOpen(false)}>
                    {/* <Link to="/help">HELP & ADVICE</Link> */}
                    <a href={window.location.origin + "/help"} onClick={(event) => event.preventDefault()} >
                      <Link to="/help">
                        HELP & ADVICE
                      </Link>
                    </a>
                  </li>
                  <li id="more" className={`${navDropdown.more ? 'hidedropdown' : ""}`} >
                    {/* <Link to="#" onClick={() => setMenuOpen(false)}>
                      MORE
                      <span className="mob_arrow_span"
                        onClick={(e) => handleDropdown(e, 'more')}
                      >
                        <i className="fa fa-angle-right mob_arrow1"></i>
                      </span>
                    </Link> */}

                    <a href={"#"} onClick={(event) => event.preventDefault()} >
                      <Link to="#" onClick={() => setMenuOpen(false)}>
                        MORE
                        <span className="mob_arrow_span"
                          onClick={(e) => handleDropdown(e, 'more')}
                        >
                          <i className="fa fa-angle-right mob_arrow1"></i>
                        </span>
                      </Link>
                    </a>

                    {/* <ul id="dropdown">
                    <li onClick={() => setMenuOpen(false)}>
                      <Link to="/wheelchair-accessible-minibus">
                        WHEELCHAIR BUS
                      </Link>
                    </li>
                    <li onClick={() => setMenuOpen(false)}>
                      <Link to="/dmuk">DISABLED MOTORING UK</Link>
                    </li>
                    <li onClick={() => setMenuOpen(false)}>
                      <Link to="/contact-us">CONTACT US</Link>
                    </li>
                    {/* <li onClick={() => setMenuOpen(false)}>
                    <Link to="/cancel-ads">CANCEL ADVERT</Link>
                  </li> */}
                    {/* </ul> */}
                    <div id="dropdown">
                      <div className="dropdown-buy">
                        <DropDownMenu name="more" setMenuOpen={setMenuOpen} />
                      </div>
                    </div>
                  </li>
                  <li
                    onClick={() => {

                      // const id = user ? `profile/${user._id}` : "signup";
                      if (!user) {
                        dispatch(toggleModal(!modalOpen))
                      }
                      else {
                        history.push(`/profile/${user._id}`);

                      }
                      // history.push(`/${id}`);
                      setMenuOpen(false)
                    }}
                    className="my-auto"
                  >
                    <a className="sell-your-wav" href={user ? (window.location.origin + `/profile/${user._id}`) : ""} onClick={(event) => event.preventDefault()} >
                      <AiFillCar /> Sell Your Wav
                    </a>
                  </li>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/* {buyMenu && (
        <div className="dropdown-buy">
          <DropDownMenu />
        </div>
      )} */}

        {
          modalOpen &&
          // <OutsideAlerter>
          <LoginComponent />
          // </OutsideAlerter>
        }
      </div>
    </>
  );
};

export default MainNavbar;
