import React from "react";
import HireDatePicker from "./HireDatePicker";

const HireDateAndPasscode = ({
  setStartDate,
  startDate,
  setPostCode,
  postCode,
}) => {
  return (
    <div className="hire-date-and-passcode">
      <div className="date-and-passcode-wrapper">
        <div className="date">
          <h3>Please set your start date</h3>
          <HireDatePicker startDate={startDate} setStartDate={setStartDate} />
        </div>
        <div className="passcode">
          <h3>Please enter post code or Pick an airport</h3>
          {/* <div className="checkbox">
            <input type="checkbox" name="checkbox" id="checkbox" />
            <label htmlFor="checkbox">
              Not sure about Postcode? Select an airport to pickup
            </label>
          </div> */}
          <textarea
            style={{ width: "100%" }}
            name="postcode"
            id="postcode"
            value={postCode}
            onChange={(e) => setPostCode(e.target.value)}
            rows="3"
            required
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default HireDateAndPasscode;
