import React from "react";
import SingleCard from "./SingleCard";

const Cards = ({ wavs }) => {
  return (
    <div className="mt-5">
      <div className="wrapper">
        <div className="row w-100 m-auto">
          {wavs.slice(0, 6).map((wav) => {
            return (
              <div key={wav._id} className="col-sm-12 col-md-6 col-lg-4 mt-3">
                <SingleCard wav={wav} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Cards;
