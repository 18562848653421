import React, { useState, useEffect } from "react";
import "./style.scss";
import { AiFillStar, AiOutlineClear, AiOutlineSearch } from "react-icons/ai";
import BasicSelect from "./SelectFIlter";
import { useDispatch, useSelector } from "react-redux";
import {
  searchWavs,
  getWavDetails,
  getWavs,
  getMakes,
  getModels,
} from "../../../redux/actions/wavActions";
import { useHistory } from "react-router-dom";
import PriceSlider from "../../Search/PriceSlider";
import { filterWavsAccordingToPriceRange, getCheapestAndCostliestWavPrice } from "../../../Helpers/utils";



const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
console.log("environment", environment)
const url = environment === "production" ? prodURL : devURL;



const MainLayout = () => {
  // const [selected, setSelected] = useState("passengers");
  const [bodyType, setBodyType] = React.useState("");
  const [make, setMake] = React.useState("");
  const [features, setFeatures] = useState([]);
  const [model, setModel] = React.useState("");
  const [keywords, setKeywords] = React.useState("");
  const [alreadyAdded, setAlreadyAdded] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const detailsFromState = useSelector((state) => state.wavs.details);
  const wavsFromState = useSelector((state) => state.wavs.list);
  const [activeWavs, setActiveWavs] = useState([])

  // const [minPrice, setMinPrice] = useState(0);
  // const [maxPrice, setMaxPrice] = useState(1000000);
  const [priceRange, setPriceRange] = React.useState([0, 100000]);
  const [priceSliderRange, setPriceSliderRange] = React.useState([0, 100000]);

  const [filteredWavs, setFilteredWavs] = useState([])

  const [wavsList, setWavsList] = useState([]);

  const { makes: makesList, models: modelsList } = useSelector((state) => state.wavs);

  const [filteredModelsList, setFilteredModelsList] = useState([]);

  useEffect(() => {
    dispatch(getMakes());
    dispatch(getModels());
  }, [])

  useEffect(() => {
    setFilteredModelsList(modelsList)
  }, [modelsList])


  useEffect(() => {
    if (wavsFromState) {
      // console.log("wavsFromState", wavsFromState)
      let activeWavsList = wavsFromState.filter((ele) => ele.status == "active")
      setActiveWavs(activeWavsList)
      setFilteredWavs(activeWavsList)

      // console.log("activeWavsList", activeWavsList)
      if (activeWavsList.length > 0) {
        let { lowestVal, highestVal } = getCheapestAndCostliestWavPrice(activeWavsList)
        console.log("lowestVal, highestVal", lowestVal, highestVal)
        setPriceSliderRange([lowestVal, highestVal])
        setPriceRange([lowestVal, highestVal])
      }
    }
  }, [wavsFromState])

  // const filteredByPassenger = activeWavs.filter(
  //   (wav) => wav.wav_type !== "Drive From Wheelchair"
  // );

  // const filteredByDriver = activeWavs.filter(
  //   (wav) => wav.wav_type === "Drive From Wheelchair"
  // );

  // const filteredByType =
  //   selected === "passengers" ? filteredByPassenger : filteredByDriver;
  // console.log("activeWavs.length", activeWavs.length)


  // const filteredByType = activeWavs && activeWavs.length > 0 && activeWavs.filter((wav) => {
  //   let price = 0
  //   if (Object.entries(wav).length > 0) {
  //     wav.price_2 = wav.price.replace(/[^0-9]/g, "")
  //     price = parseFloat(wav.price_2);
  //   }
  //   if (price >= priceRange[0] && price <= priceRange[1]) {
  //     return wav;
  //   }
  // });

  // console.log("filteredByType", filteredByType)
  // const filteredByBody =
  //   filteredByType
  //   // && filteredByType.length > 0
  //   &&
  //   filteredByType.filter((wav) => wav.body === bodyType);

  const [filteredByMake, setFilteredByMake] = useState([])

  // const filteredByMake =
  //   bodyType !== ""
  //     ? filteredByBody && filteredByBody.filter((wav) => wav.make.trim() === make.trim())
  //     : filteredByType &&
  //     //  filteredByType.length > 0 &&
  //     filteredByType.filter((wav) => wav.make.trim() === make.trim());

  const [filteredByModel, setFilteredByModel] = useState([])

  // const filteredByModel =
  //   make !== ""
  //     ? filteredByMake && filteredByMake.filter((wav) => wav.models === model)
  //     : filteredByType &&
  //     //  filteredByType.length > 0 &&
  //     filteredByType.filter((wav) => wav.models === model);

  const filteredByFeatures =
    model !== ""
      ? filteredByModel && filteredByModel.filter((wav) => {
        const wavs = [];
        for (let keyword of keywords) {
          const featureIncluded = wav.features.includes(keyword);
          wavs.push(featureIncluded);
        }
        const notFalse = wavs.includes(false);
        if (!notFalse) {
          return wav;
        }
      })
      : activeWavs.filter((wav) => {
        const wavs = [];
        for (let keyword of keywords) {
          const featureIncluded = wav.features.includes(keyword);
          wavs.push(featureIncluded);
        }
        const notFalse = wavs.includes(false);
        if (!notFalse) {
          return wav;
        }
      });

  const newUniqueFeatures = [];

  const filteredFeatures =
    filteredByModel &&
    filteredByModel.length > 0 &&
    filteredByModel.map((wav) => {
      wav.features.map((feature) => {
        const isUnique = newUniqueFeatures.includes(feature);
        if (!isUnique) {
          newUniqueFeatures.push(feature);
        }
      });
      return newUniqueFeatures;
    });
  // console.log("filteredByBody.length", filteredByBody.length)
  // console.log("filteredByMake.length", filteredByMake, filteredByMake.length)
  // console.log("filteredByModel.length", filteredByModel, filteredByModel.length)
  // console.log("filteredByFeatures.length", filteredByFeatures.length)
  // console.log("filteredByType.length", filteredByType, filteredByType.length)


  // const length =
  //   bodyType !== "" && make === "" && model === ""
  //     ? filteredByBody.length
  //     : make !== "" && model === ""
  //       ? filteredByMake.length
  //       : model !== "" && keywords.length <= 0
  //         ? filteredByModel.length
  //         : bodyType !== "" || make !== "" || model !== ""
  //           ? filteredByFeatures.length
  //           : filteredByType.length;


  // const length =
  //   make == "" && model == ""
  //     ? filteredWavs.length
  //     :
  //     make !== "" && model === ""
  //       ? filteredByMake.length
  //       : model !== ""
  //         ? filteredByModel.length
  //         :
  //         filteredWavs.length;


  // console.log("length-------", length)

  // const makes =
  //   // bodyType === ""
  //   //   ?
  //   filteredWavs && filteredWavs.map((wav) => wav.make)
  // : filteredByBody
  // // && filteredByBody.length > 0
  // && filteredByBody.map((wav) => wav.make);

  // const filteredMakes = [];
  // const uniqueMakes =
  //   makes &&
  //   makes.length > 0 &&
  //   makes.filter((make) => {
  //     // const isUnique = filteredMakes.includes(make);
  //     const isUnique = filteredMakes.some((ele) => ele.trim().toLowerCase() == make.trim().toLowerCase());
  //     if (!isUnique) {
  //       filteredMakes.push(make);
  //     }
  //   });

  const filteredMakes = makesList.map((ele) => ele.title)


  // const models =
  //   make === ""
  //     ? filteredWavs && filteredWavs.map((wav) => wav.models)
  //     : filteredByMake
  //     // && filteredByMake.length > 0
  //     && filteredByMake.map((wav) => wav.models);

  // console.log('wavsList1', wavsList)

  // const models = wavsList.map((wav) => wav.models)

  // const filteredModels = [];
  // const uniqueModels =
  //   models &&
  //   models.length > 0 &&
  //   models.filter((model) => {
  //     const isUnique = filteredModels.some((ele) => ele.trim().toLowerCase() == model.trim().toLowerCase());
  //     if (!isUnique) {
  //       filteredModels.push(model);
  //     }
  //   });



  const filteredModels = filteredModelsList.map((ele) => ele.title);

  useEffect(() => {
    getFeatures();
    dispatch(getWavs());
    dispatch(getWavDetails());
  }, []);
  const getFeatures = async () => {
    try {
      const response = await fetch(`${url}/features/all`);
      if (response.ok) {
        const data = await response.json();
        setFeatures(data.data.features);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [inputCounter, setInputCounter] = useState(0)
  // console.log('inputCounter---', inputCounter)
  const handleChange = (e, type) => {
    if (e.target.value != '') {
      setInputCounter(inputCounter + 1)

    }
    if (type === "body") {
      setBodyType(e.target.value);
    } else if (type === "make") {
      setMake(e.target.value);

      handleFilterChange(e.target.value, model, priceRange);

      let modelsListArr = e.target.value && e.target.value != "" ? modelsList.filter((ele) => ele.make_id.title.trim().toLowerCase() == e.target.value.trim().toLowerCase()) : modelsList
      setFilteredModelsList(modelsListArr)


      // const filteredByMake =
      //   filteredWavs &&
      //   filteredWavs.filter((wav) => wav.make.trim() === e.target.value.trim());

      // setFilteredByMake(filteredByMake)
      // setWavsList(filteredByMake)
      // console.log("filteredWavs", filteredWavs)
      // console.log("filteredByMake", filteredByMake)
      // if (filteredByMake.length > 0) {
      //   let { lowestVal, highestVal } = getCheapestAndCostliestWavPrice(filteredByMake)
      //   console.log("lowestVal, highestVal", lowestVal, highestVal)

      //   setPriceSliderRange([lowestVal, highestVal])
      //   setPriceRange([lowestVal, highestVal])
      // }


      // setFilteredWavs(filteredByMake)


    } else if (type === "model") {
      setModel(e.target.value);
      console.log("MODEL-----", e.target.value)

      handleFilterChange(make, e.target.value, priceRange)

      // const filteredByModel =
      //   make !== ""
      //     ? filteredByMake && filteredByMake.filter((wav) => wav.models === e.target.value)
      //     : filteredWavs &&
      //     filteredWavs.filter((wav) => wav.models === e.target.value);

      // setFilteredByModel(filteredByModel)
      // setWavsList(filteredByModel)


      // if (filteredByModel.length > 0) {
      //   let { lowestVal, highestVal } = getCheapestAndCostliestWavPrice(filteredByModel)
      //   setPriceSliderRange([lowestVal, highestVal])
      //   setPriceRange([lowestVal, highestVal])
      // }

      // setFilteredWavs(filteredByModel)


    } else {
      const keyword =
        keywords &&
        keywords.length > 0 &&
        keywords.find((keyword) => keyword === e.target.value);
      if (!keyword) {
        setKeywords([...keywords, e.target.value]);
      } else {
        setAlreadyAdded(true);
        setTimeout(() => {
          setAlreadyAdded(false);
        }, 2000);
      }
    }
  };




  const handleFilterChange = (make, model, priceRange) => {
    let wavsFiltered = []
    if (make && make != "") {
      wavsFiltered = activeWavs.filter((wav) => wav.make.trim().toLowerCase() === make.trim().toLowerCase());

      console.log("wavsFiltered", wavsFiltered)
    }
    if (make == "") {
      wavsFiltered = activeWavs
    }
    if (model != "") {
      wavsFiltered = make !== ""
        ? wavsFiltered && wavsFiltered.filter((wav) => wav.models.trim().toLowerCase() === model.trim().toLowerCase())
        : activeWavs &&
        activeWavs.filter((wav) => wav.models.trim().toLowerCase() === model.trim().toLowerCase());
    }
    if (priceRange) {
      let clonewavsFiltered = filterWavsAccordingToPriceRange(wavsFiltered, priceRange)
      wavsFiltered = clonewavsFiltered
    }
    setWavsList(wavsFiltered)
  }


  useEffect(() => {
    handleFilterChange(make, model, priceRange)
    console.log("activeWavs", activeWavs)
  }, [activeWavs])


  const length = wavsList.length


  // console.log("filteredByModel------", filteredByModel)
  const handleSubmit = () => {
    // history.push("/search", {
    //   priceRange: priceRange
    // });
    // dispatch(searchWavs(bodyType, make, model, keywords));

    console.log("handleSubmit", "make", make)
    dispatch(
      searchWavs(
        bodyType,
        make,
        model,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        keywords,
        // selected
        "",
        ""
      )
    );

    history.push("/search", {
      priceRange: priceRange
    });


  };

  const handleClear = () => {
    setBodyType("");
    setMake("");
    setModel("");
    setKeywords("");
    setPriceRange(priceSliderRange)
  };
  const deleteKeyword = (btn) => {
    const filtered = keywords.filter((keyword) => keyword !== btn);

    setKeywords(filtered);
  };
  return (
    <div className="main-layout">
      <div id="main">
        <div className="search-wrapper">
          <h2 className="title">500+</h2>
          <div className="subtitle">
            Wheelchair Accessible Vehicles Available To Buy Now
          </div>
          <div className="search-component">
            {/* <div className="upper-part">
              <div className="types d-flex">
                <div
                  onClick={() => setSelected("passengers")}
                  className={
                    selected === "passengers"
                      ? "passengers on-focus"
                      : "passengers"
                  }
                >
                  ADAPTED FOR PASSENGERS
                </div>
                <div
                  onClick={() => setSelected("drivers")}
                  className={
                    selected === "drivers" ? "drivers on-focus" : "drivers"
                  }
                >
                  ADAPTED FOR DRIVERS
                </div>
              </div>
            </div> */}
            <div className="lower-part">
              <div className="main">
                {/* <div className="select-filter">
                  <BasicSelect
                    name="Choose Body"
                    bodyType={bodyType}
                    list={detailsFromState.body}
                    make={make}
                    model={model}
                    keywords={keywords}
                    handleChange={handleChange}
                  />
                </div> */}
                <div className="select-filter">
                  <BasicSelect
                    name="Choose Make"
                    bodyType={bodyType}
                    list={filteredMakes}
                    make={make}
                    model={model}
                    keywords={keywords}
                    handleChange={handleChange}
                  />
                </div>
                <div className="select-filter">
                  <BasicSelect
                    name="Choose Model"
                    bodyType={bodyType}
                    list={filteredModels}
                    make={make}
                    model={model}
                    keywords={keywords}
                    handleChange={handleChange}
                  />
                </div>

                <div className="select-filter">
                  <PriceSlider
                    value={priceRange}
                    setValue={(priceRange) => {
                      setInputCounter(inputCounter + 1)

                      setPriceRange(priceRange)
                      handleFilterChange(make, model, priceRange)

                      // let clonefilteredWavs = activeWavs.filter((wav) => {
                      //   let price = 0
                      //   if (Object.entries(wav).length > 0) {
                      //     wav.price_2 = wav.price.replace(/[^0-9]/g, "")
                      //     price = wav.price_2 ? parseFloat(wav.price_2) : 0;
                      //   }
                      //   if (price >= priceRange[0] && price <= priceRange[1]) {
                      //     return wav;
                      //   }
                      // });

                      // setFilteredWavs(clonefilteredWavs)

                    }}
                    priceSliderRange={priceSliderRange}
                  // minPrice={minPrice}
                  // maxPrice={maxPrice}
                  // setMinPrice={(e) => {
                  //   setInputCounter(inputCounter + 1)
                  //   setMinPrice(e)
                  // }}
                  // setMaxPrice={(e) => {
                  //   setInputCounter(inputCounter + 1)

                  //   setMaxPrice(e)
                  // }}
                  />
                </div>

                {/* <div className="select-filter">
                  <BasicSelect
                    name="Keywords"
                    bodyType={bodyType}
                    make={make}
                    // list={detailsFromState.features}
                    list={newUniqueFeatures}
                    model={model}
                    keywords={keywords}
                    handleChange={handleChange}
                  />
                </div> */}
                <div className="search-btns">
                  <button
                    onClick={handleSubmit}
                    className="btn search-btn  mt-3"
                  >
                    {/* <AiOutlineSearch /> {length} WAVS */}
                    <AiOutlineSearch /> {inputCounter > 0 && `${length} WAVS`}
                  </button>
                  <button onClick={handleClear} className="btn search-btn mt-3">
                    <AiOutlineClear /> CLEAR
                  </button>
                </div>
              </div>
              <div className="keywords">
                {keywords &&
                  keywords.length > 0 &&
                  keywords.map((keyword) => (
                    <button
                      style={{ textTransform: "capitalize" }}
                      onClick={(e) => {
                        e.preventDefault();
                        deleteKeyword(keyword);
                      }}
                      key={keyword}
                    >
                      {keyword}
                    </button>
                  ))}
              </div>
              {alreadyAdded && (
                <div className="already-added">Already in the list</div>
              )}
            </div>
          </div>
          {/* <div className="call-to-action">
          <div className="star">
            <AiFillStar color="yellow" />
          </div>
          <div className="text">
            <h2>We Buy Any WAV</h2>
            <div>Need a quick sale on your WAV?</div>
            <div>Call us now on 0800 832 1795 or fill in this form</div>
          </div>
          <div className="star">
            <AiFillStar color="yellow" />
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
