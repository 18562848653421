import React from "react";

const HelpAndAdviceWheelchairAccessible = () => {
  return (
    <div className="help-and-advice-wheelchair-accessible ">
      <div className="content">
        <div className="text">
          <div className="paragraph">
            {/* <h1>Wheelchair accessible vehicle</h1> */}
            <h2>Wheelchair accessible vehicle</h2>
            <p>
              Purchasing a wheelchair accessible vehicle is a big decision,
              there are several different types of wheelchair accessible
              vehicles. Deciding which one would be best for you will depend on
              your individual needs and requirements, comparing just the price
              is not the answer. However we hope the following guide provides
              all the information you need to help find your perfect WAV. Keep
              reading to discover how WAV Compare can assist you to find your
              perfect wheelchair accessible vehicle.
            </p>
          </div>
          <div className="paragraph">
            {/* <h1>What is a wheelchair accessible vehicle?</h1> */}
            <h2>What is a wheelchair accessible vehicle?</h2>
            <p>
              A WAV is a vehicle that has been built or modified to allow access
              by a person in a wheelchair. For many wheelchair users, the act of
              transferring from their chair and into a normal car can be
              uncomfortable, and sometimes even painful, or dangerous. With a
              WAV, you can enter into the vehicle seated safely and comfortably
              in your own chair.
            </p>
          </div>
        </div>
        <div className="image">
          <img
            src="https://www.flexerent.co.uk/hubfs/9-seater-new.png"
            alt=""
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
};

export default HelpAndAdviceWheelchairAccessible;
