import React, { useEffect, useRef, useState } from "react";

import "./accordion.css";

import { ReactComponent as DownArrow } from "../../images/down-arrrow.svg";


const FeatureElements = ({ features, selectFeature }) => {
    return (
        <div className="row">
            {
                features && features.map((feature) => {
                    return (
                        <button
                            onClick={() => selectFeature(feature._id)}
                            className={feature.selected ? "feature-selected" : null}
                            id={feature._id}
                            type="button"
                            key={feature._id}
                        >
                            {feature.title}
                        </button>
                    )
                })
            }
        </div>
    )
}

const SelectedFeatureElements = ({ selectedFeatures, selectFeature }) => {
    return (
        <div className="row">
            {
                selectedFeatures && selectedFeatures.map((feature) => {
                    return (
                        <button
                            onClick={() => selectFeature(feature._id)}
                            className={feature.selected ? "feature-selected" : null}
                            id={feature._id}
                            type="button"
                            key={feature._id}
                        >
                            {feature.title}
                        </button>
                    )
                })
            }
        </div>
    )
}

function Accordion(props) {
    const [active, setActive] = useState(true);
    const content = useRef(null);
    const [height, setHeight] = useState("0px");

    useEffect(() => {
        console.log("Height for ", props.item.title, ": ", height);
    }, [height]);

    function toggleAccordion() {
        setActive(!active);
        props.setSelected(props.index)
        setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
    }

    useEffect(() => {
        if (props.selected != props.index) {
            setHeight("0px");
            setActive(false)
        }
        if (props.selected == props.index) {
            setHeight(`max-content`)
        }
    }, [props.selected])


    const [selectedFeatures, setSelectedFeatures] = useState([]);

    useEffect(() => {
        if (props.item.features_list.length > 0) {
            let selected = props.item.features_list.filter((ele) => {
                return ele.selected == true
            })
            setSelectedFeatures(selected)
        }
    }, [props.item.features_list])


    return (
        <div className="accordion__section">
            <div
                className={`accordion bottom-b ${active ? "active" : ""}`}
                onClick={toggleAccordion}
            >
                <p className="accordion__title">{props.item.title} <p className="descr">{props.item.description}</p></p>
                <span style={{ marginLeft: "20px" }}>
                    {active ?
                        <DownArrow />
                        :
                        <DownArrow />
                    }

                </span>
            </div>
            <div
                ref={content}
                style={{ maxHeight: `${height}` }}
                className="accordion__content"
            >
                <div
                    className="accordion__text"

                >
                    <FeatureElements features={props.item.features_list} selectFeature={props.selectFeature} />
                </div>
            </div>
            <div
                className="accordion__text"
            >
                {!active && <SelectedFeatureElements selectedFeatures={selectedFeatures} selectFeature={props.selectFeature} />}
            </div>

        </div>
    );
}

export default Accordion;