import React from "react";
import { AiFillStar } from "react-icons/ai";
import DealerPageReviewCard from "./DealerPageReviewCard";
import DisplayRating from "./DisplayRating";
const DealerPageNavPanelReviewList = ({ dealer }) => {
  const customerService = dealer.reviews
    ? dealer.reviews.map((review) => review.customer_service)
    : [];
  const buyingProcess = dealer.reviews
    ? dealer.reviews.map((review) => review.buying_process)
    : [];
  const overallExperience = dealer.reviews
    ? dealer.reviews.map((review) => review.overall_experience)
    : [];
  const yess = dealer.reviews.filter(
    (review) => review.recommendation === "yes"
  );
  const nos = dealer.reviews.filter((review) => review.recommendation === "no");

  const averageCustomerService =
    customerService.length > 0
      ? Number(
          customerService.reduce((a, b) => a + b) / customerService.length
        ).toFixed(1)
      : 5;
  const averageBuyingProcess =
    buyingProcess.length > 0
      ? Number(
          buyingProcess.reduce((a, b) => a + b) / buyingProcess.length
        ).toFixed(1)
      : 5;
  const averageOverallExperience =
    overallExperience.length > 0
      ? Number(
          overallExperience.reduce((a, b) => a + b) / overallExperience.length
        ).toFixed(1)
      : 5;
  const averageRating = Number(
    (parseFloat(averageCustomerService) +
      parseFloat(averageBuyingProcess) +
      parseFloat(averageOverallExperience)) /
      3
  ).toFixed(1);

  return (
    <div className="dealer-reviews">
      <h4>Dealer Rating</h4>
      <div className="dealer-ratings">
        <div className="average-rating">
          <div>Average rating</div>
          <div className="rating-big">
            <span>{averageRating}</span>/5
          </div>
          <div className="stars">
            <DisplayRating name="average-rating" value={averageRating} />
          </div>
          <div className="ratings-amount">
            (Based on {dealer.reviews.length} ratings.)
          </div>
        </div>
        <div className="detailed-rating">
          <div className="c-service">
            <h6>Customer Service</h6>
            <div className="reviews-star">
              <div className="stars">
                <DisplayRating
                  name="customer-service-average"
                  value={averageCustomerService}
                />
              </div>
              <div className="review-text">
                {averageCustomerService} out of 5.0
              </div>
            </div>
          </div>
          <div className="buying-process">
            <h6>Buying Process</h6>
            <div className="reviews-star">
              <div className="stars">
                <DisplayRating
                  name="buying-process-average"
                  value={averageBuyingProcess}
                />
              </div>
              <div className="review-text">
                {averageBuyingProcess} out of 5.0
              </div>
            </div>
          </div>
          <div className="overall-experience">
            <h6>Overall Experience</h6>
            <div className="reviews-star">
              <div className="stars">
                <DisplayRating
                  value={averageOverallExperience}
                  name="overall-experience-average"
                />
              </div>
              <div className="review-text">
                {averageOverallExperience} out of 5.0
              </div>
            </div>
          </div>
          <div className="recommend">
            <h6>Recommend</h6>
            <div>
              <span className="yes mr-3">Yes({yess.length})</span>
              <span className="no">No({nos.length})</span>
            </div>
          </div>
        </div>
      </div>

      <div className="reviews">
        <h4>Reviews ({dealer && dealer.reviews && dealer.reviews.length})</h4>
        <div className="reviews-cards">
          {dealer &&
            dealer.reviews &&
            dealer.reviews.map((review) => (
              <DealerPageReviewCard review={review} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default DealerPageNavPanelReviewList;
