import React from "react";
import { TiMinus } from "react-icons/ti";
import { RiWheelchairFill } from "react-icons/ri";
import { FaCarAlt } from "react-icons/fa";
const HelpAndAdviceOthers = () => {
  return (
    <div className="help-and-advice-others">
      <div className="container">
        {/* <h1>Other choices that are the same when you purchase any vehicle</h1> */}
        <h2>Other choices that are the same when you purchase any vehicle</h2>
        <div className="minus-icons">
          <span>
            <TiMinus />
          </span>
          <span>
            <TiMinus />
          </span>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="icon">
              <RiWheelchairFill />
            </div>
            <div className="text">
              <h5>AUTOMATIC AND MANUAL</h5>
              <p>
                This usually is a choice of personal preference of the driver.
                For a wheelchair user who will be driving it is more common to
                have an automatic gearbox as this reduces the adaptations
                required.
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="icon">
              <FaCarAlt />
            </div>
            <div className="text">
              <h5>NEW, USED, OR HIRE</h5>
              <p>
                There are, of course lots of advantages in purchasing a new
                vehicle, but prices can be prohibitively high for many people.
                However, at WAV Compare we offer a wide selection or pre-owned
                vehicles at very competitive prices. Hiring is a great option if
                you just want a WAV to go on holiday or for occasional use. It
                is also a good idea to hire a vehicle before buying a similar
                one. That way you can check it out before purchasing.
              </p>
            </div>
          </div>
        </div>
        {/* <h1>Latest blog post</h1> */}
        <h2>Latest blog post</h2>
        <div className="minus-icons">
          <span>
            <TiMinus />
          </span>
          <span>
            <TiMinus />
          </span>
        </div>
        <div className="links">
          <a href="#"> Xplore Wheel </a> |
          <a href="#"> Quickie Nitrum Wheelchair Available</a> |
          <a href="#"> Cars For Mobility Scooters</a> |
          <a href="#"> Wheelchair Accessible Vauxhall Zafira</a> |
          <a href="#"> Wheelchair Accessible Kia Sedona WAV</a> |
          <a href="#"> Wheelchair Passenger – Ride Up Front WAV</a> |
          <a href="#"> Wheelchair Accessible Minibus </a> |
          <a href="#"> Wheelchair Accessible Cars Lytham St Annes</a> |
          <a href="#"> Wheelchair Accessible Cars Somerset </a> |
          <a href="#"> Wheelchair Cars</a>
          <a href="#"> West Midlands</a>
        </div>
      </div>
    </div>
  );
};

export default HelpAndAdviceOthers;
