import React, { useEffect, useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { BsMap } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
import { AiFillCar } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { getWavs } from "../../redux/actions/wavActions";
import { useDispatch } from "react-redux";
import DisplayRating from "./DisplayRating";
import { FaCar } from "react-icons/fa";
import { BiShow, BiHide } from "react-icons/bi";
import Dealers from "./Dealers";
const DealersCard = ({ dealer }) => {
  const [showNumber, setShowNumber] = useState(true);
  const history = useHistory();
  const navigate = (id) => {
    history.push(`/dealers/${id}`);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWavs());
  }, []);
  return (
    <div className="dealers-card">
      <ul>
        <li
          style={{ cursor: "pointer" }}
          onClick={() => navigate(dealer._id)}
          className="dealer-logo"
        >
          <img
            src={
              dealer.logo === ""
                ? "/assets/user.jpg"
                : `https://dev.wavcompare.com/uploads/${dealer.logo}`
            }
            alt="company logo"
          />
        </li>
        <li
          style={{ cursor: "pointer" }}
          onClick={() => navigate(dealer._id)}
          className="dealers-review"
        >
          <h4 className="ml-2">{dealer.name}</h4>
          <div id="review">
            <DisplayRating
              name="main-reviews"
              value={dealer.rewievs_number === 0 ? 5 : dealer.stars}
            />{" "}
            <div>(Reviews {`${dealer.rewievs_number}`})</div>
          </div>
        </li>
        <li className="dealers-number">
          <div className="dealers-number-icon"></div>
          <div className="dealers-text">
            <div className="number">
              <FaCar />
              {dealer.wavs_in_stock}
            </div>
            <p>WAVs in stock</p>
          </div>
        </li>
        <li className="dealers-card-contact-number">
          <div>
            <span className="mr-2">
              <IoMdCall />
            </span>
            <span className="number">
              {showNumber
                ? dealer.phone_number
                : `${dealer.phone_number.substring(0, 4)}*****`}
            </span>
            {/* <span onClick={() => setShowNumber(!showNumber)}>
              {showNumber ? <BiHide /> : <BiShow />}{" "}
              <span className="show-number">
                {showNumber ? "Hide Number" : "Show Number"}
              </span>
            </span> */}
          </div>
        </li>
        <li className="dealers-card-location">
          <div className="dealers-card-location-icon mr-2">
            <HiLocationMarker />
          </div>
          <div className="dealers-card-location-details">
            {dealer && dealer.address && dealer.address}
          </div>
          <div className="see-map">
            <a href={`${dealer.map_link}`} target="_blank">
              <span>
                <BsMap />
              </span>
              See map
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DealersCard;
