import React, { useState } from "react";
import { MdPhotoCamera } from "react-icons/md";
import { AiOutlineUpload } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CHECK_LOGGED_IN_USER } from "../../redux/constants/constants";
import { useEffect } from "react";
import Cookies from "universal-cookie";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;

const AccountInformation = ({ type, dealerRole, setDealerRole }) => {
  const [name, setName] = useState("");
  const [login, setLogin] = useState("");
  const [image, setImage] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dealerId, setDealerId] = useState("");
  const [map, setMap] = useState("");
  const [logo, setLogo] = useState(null);
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("jwt");
  const user = cookies.get("user");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const params = useParams();
  const id = params.id;
  const getUser = async () => {
    try {
      const response = await fetch(`${url}/dealer/${id}`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        const { dealer } = data.data;
        setName(dealer.name);
        setLogin(dealer.login);
        setDealerRole(dealer.role);
        setDealerId(dealer._id);
        setLogo(dealer.logo);
        dealer.address && setAddress(dealer.address);
        dealer.postcode && setPostcode(dealer.postcode);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUser();
  }, []);
  useEffect(() => {
    getUser();
  }, [id]);

  const updateDealer = async (
    name,
    login,
    logo,
    currentPassword,
    newPassword
  ) => {
    let formData = new FormData();
    // let file = ImageUpld.file;
    image && formData.append("logo", image);
    name !== "" && formData.append("name", name);
    login !== "" && formData.append("login", login);
    currentPassword !== "" && formData.append("old_password", currentPassword);
    newPassword !== "" && formData.append("new_password", newPassword);

    const links = {
      web_site: website !== "" ? website : "#",
      facebook: facebook !== "" ? facebook : "#",
      twitter: twitter !== "" ? twitter : "#",
      youtube: youtube !== "" ? youtube : "#",
    };

    address !== "" && formData.append("address", address);
    postcode !== "" && formData.append("postcode", postcode);
    map !== "" && formData.append("map_link", map);

    dealerRole !== "" && type === "dealer" && formData.append("role", "dealer");
    // formData.append("links", links);
    formData.append("links", JSON.stringify(links));

    const response = await fetch(`${url}/dealer/${user._id}`, {
      method: "PATCH",
      body: formData,
      headers: {
        authorization: `${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();

      const today = new Date();
      const tomorrow = new Date(today);
      const expiryDate = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
      cookies.remove("jwt");
      cookies.remove("user");
      cookies.set("jwt", data.data.token, { expires: expiryDate });
      cookies.set("user", data.data.updatedDealer, { expires: expiryDate });
      dispatch({
        type: CHECK_LOGGED_IN_USER,
        payload: data.data.updatedDealer,
      });
      setImage(null);
      getUser();
      setSuccess(true);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } else {
      const data = await response.json();
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };
  const handleAccountUpdate = async (e) => {
    e.preventDefault();
    updateDealer(name, login, logo, currentPassword, newPassword);
  };

  return (
    <div>
      <h2>
        {dealerRole === "user"
          ? "Add a few more details to become a dealer"
          : "EDIT ACCOUNT INFORMATION"}
      </h2>
      <form onSubmit={handleAccountUpdate} className="row">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <h3>ACCOUNT INFORMATION</h3>
          <div className="card-information">
            <div className="form-group">
              <label htmlFor="name">
                Full Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                aria-describedby="name"
              />
            </div>
            <div className="row">
              <div className="upload-photos">
                <label htmlFor="upload-photo">
                  <div
                    className="icon"
                    style={logo ? { background: "none" } : null}
                  >
                    {logo ? (
                      <img
                        src={`https://dev.wavcompare.com/uploads/${logo}`}
                        alt=""
                        className="img-fluid"
                        style={{ marginBottom: "10px", maxWidth: "200px" }}
                      />
                    ) : (
                      <MdPhotoCamera />
                    )}

                    <div>Choose Photo</div>
                  </div>
                  <div className="text">
                    <AiOutlineUpload />
                    Upload {image && "| " + image.name}
                  </div>
                </label>
                <input
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                  id="upload-photo"
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="account-name">
                Login <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                className="form-control"
                id="account-name"
                placeholder="Account name"
                aria-describedby="accountName"
              />
            </div>
            {type === "dealer" && (
              <div>
                <div className="form-group">
                  <label htmlFor="address">
                    Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="form-control"
                    id="address"
                    placeholder="Enter your address"
                    aria-describedby="address"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="map">
                    Map Link <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    value={map}
                    onChange={(e) => setMap(e.target.value)}
                    className="form-control"
                    id="map"
                    placeholder="Enter your address's map link"
                    aria-describedby="map"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="postcode">
                    Post Code <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    value={postcode}
                    onChange={(e) => setPostcode(e.target.value)}
                    className="form-control"
                    id="postcode"
                    placeholder="Enter your postal code"
                    aria-describedby="accountName"
                    required
                  />
                </div>
                <h3 className="my-3">Links to your social media accounts</h3>
                <div className="form-group">
                  <label htmlFor="website">Website</label>
                  <input
                    type="text"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    className="form-control"
                    id="website"
                    placeholder="Enter your website link"
                    aria-describedby="website"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="facebook">Facebook</label>
                  <input
                    type="text"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                    className="form-control"
                    id="facebook"
                    placeholder="Enter your Facebook account link"
                    aria-describedby="facebook"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="twitter">Twitter</label>
                  <input
                    type="text"
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                    className="form-control"
                    id="twitter"
                    placeholder="Enter your Twitter account link"
                    aria-describedby="twitter"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="youtube">YouTube</label>
                  <input
                    type="text"
                    value={youtube}
                    onChange={(e) => setYoutube(e.target.value)}
                    className="form-control"
                    id="youtube"
                    placeholder="Enter your YouTube account link"
                    aria-describedby="youtube"
                  />
                </div>
              </div>
            )}
          </div>
          {success && (
            <div className="alert alert-success" role="alert">
              Success! Your account has been updated
            </div>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              Something went wrong
            </div>
          )}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <h3>CURRENT PASSWORD</h3>
          <div className="password-inputs">
            <div className="form-group">
              <label htmlFor="current-password">
                Current Password <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="form-control"
                id="current-password"
                placeholder="Current Password"
                aria-describedby="currentPassword"
              />
            </div>
            <div className="form-group">
              <label htmlFor="new-password">
                New Password <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="form-control"
                id="new-password"
                placeholder="New Password"
                aria-describedby="newPassword"
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">
                Confirm New Password <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="form-control"
                id="confirm-password"
                placeholder="Confirm the new Password"
                aria-describedby="confirmPassword"
              />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-success m-2">
          Update
        </button>
      </form>
    </div>
  );
};

export default AccountInformation;
