import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changePassword, selectComponent, toggleModal, verifyResetPasswordToken } from '../../redux/actions/dealerActions';
import './style.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';



const Changepassword = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [resetPasswordToken, setResetPasswordToken] = useState("")
    const [show, setShow] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [signupError, setSignupError] = useState("")


    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const token = query.get('token')
        setResetPasswordToken(token)
    }, [])

    const validationSchema = Yup.object().shape({

        password: Yup.string()
            .min(8, { length: "Password too short" })
            .matches(/\d+/, { message: { number: "Password no number" } })
            .matches(/[a-z]+/, { message: { lowercase: "Password no lowercase" } })
            .matches(/[A-Z]+/, { message: { uppercase: "Password no uppercase" } })
            .matches(/[!@#$%^&*()-+]+/, {
                message: { special: "Password no special char" }
            })
            .test(
                "Password has spaces",
                { spaces: "Password has spaces" },
                value => !/\s+/.test(value)
            )
            .required({ required: "password is required" }),

        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')

    });


    const formik = useFormik({
        initialValues: {
            password: "",
            confirm_password: ""
        },
        validationSchema,
        // validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: (data) => {
            console.log("DATA", data)
            data.token = resetPasswordToken
            handleChangePasswod(data)
        },
    });


    const handleChangePasswod = async (data) => {
        try {
            const signUpWith = await dispatch(
                changePassword(data)
            );
            console.log("resp-=====", signUpWith)
            if (signUpWith.success) {
                toast.success("Password changed successfully")

                // dispatch(toggleModal(false))
                dispatch(selectComponent("signin"))
            } else {
                setSignupError(signUpWith.error);
                toast.error(signUpWith.error)
                setTimeout(() => {
                    setSignupError("");
                }, 5000)
            }

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>

            <h1 className='pl-3'>Change Password</h1>

            <form className='mt-4 pl-3' onSubmit={formik.handleSubmit}>
                <div className='form-group'>
                    <label>Password</label>
                    <div className='position-relative'>
                        <input
                            type={show ? "text" : "password"}
                            name="password"
                            // value={inputs.password}
                            placeholder="Enter Password"
                            // onChange={handleInputChange}

                            className={
                                'form-control' +
                                (formik.errors.password && formik.touched.password
                                    ? ' is-invalid'
                                    : '')
                            }
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                        <button type="button" className='btn show_btn p-0 bg-transparent' style={{ top: "35%", right: "35px" }} onClick={() => setShow(!show)}>{show ? "HIDE" : "SHOW"}</button>

                        <div className="invalid-feedback invalid-feedback-postn">
                            {formik.errors.password && formik.touched.password
                                ? (formik.errors.password.lowercase || formik.errors.password.uppercase || formik.errors.password.length || formik.errors.password.required || formik.errors.password.spaces || formik.errors.password.special || formik.errors.password.number)
                                : null}
                        </div>
                    </div>

                </div>


                <div className='form-group pt-3'>
                    <label>Confirm Password</label>
                    <div className='position-relative'>
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirm_password"
                            placeholder="Enter Confirm Password"
                            className={
                                'form-control' +
                                (formik.errors.confirm_password && formik.touched.confirm_password
                                    ? ' is-invalid'
                                    : '')
                            }
                            onChange={formik.handleChange}
                            value={formik.values.confirm_password}
                        />
                        <button type="button" className='btn show_btn p-0 bg-transparent' style={{ top: "35%", right: "35px" }} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? "HIDE" : "SHOW"}</button>

                        <div className="invalid-feedback invalid-feedback-postn">
                            {formik.errors.confirm_password && formik.touched.confirm_password
                                ? (formik.errors.confirm_password)
                                : null}
                        </div>
                    </div>

                </div>

                <button type="submit" className='btn main_btn1 text-white mt-4'>Change Password</button>
            </form>

        </>
    )
}
export default Changepassword;