import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import shortid from "shortid";
import Cookies from "universal-cookie";
import moment from "moment";

const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;
const PaymentMethod = () => {
  const [selected, setSelected] = useState("history");
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCVV] = useState("");
  const [postCode, setPostCode] = useState("");
  const [cards, setCards] = useState([]);
  const [payments, setPayments] = useState([]);
  const cookies = new Cookies();
  const jwt = cookies.get("jwt");
  const getPaymentHistory = async () => {
    try {
      const response = await fetch(`${url}/payment/all`, {
        headers: {
          authorization: jwt,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setPayments(data.data.payments);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const newCard = {
      name,
      cardNumber,
      expiry,
      cvv,
      postCode,
    };

    setCards([...cards, newCard]);
    setName("");
    setCardNumber("");
    setExpiry("");
    setCVV("");
    setPostCode("z");
  };
  useEffect(() => {
    getPaymentHistory();
  }, []);
  // dealer: {
  //   name: "Palonchi Palonchiyev",
  //   email: "palonchi@gmail.com",
  //   phone: "1234567",
  //   address: "lalaland",
  //   points: 0
  // }
  return (
    <div>
      {/* <div className="header">
        <div
          onClick={() => setSelected("history")}
          className={selected === "history" ? "selected-billing" : ""}
        >
          History
        </div>
        <div
          className={selected === "billing-details" ? "selected-billing" : ""}
          onClick={() => setSelected("billing-details")}
        >
          Billing Details
        </div>
        <div
          className={selected === "payment-methods" ? "selected-billing" : ""}
          onClick={() => setSelected("payment-methods")}
        >
          Payment Methods
        </div>
      </div> */}
      {selected === "history" && (
        <div className="history-items">
          {/* <div className="calendar">
            <div className="previous">
              <AiOutlineArrowLeft />
              April 2022
            </div>
            <div className="current">May 2022</div>
            <div className="next">
              June 2022 <AiOutlineArrowRight />
            </div>
          </div> */}
          <div className="history-header text-left p-4">
            <h3
              style={{
                width: "50%",
                paddingBottom: "10px",
                borderBottom: "#ddd 1px solid",
              }}
              className=""
            >
              Payment History
            </h3>
          </div>
          <div className="items">
            <div className="item-header">
              <div className="date">Date</div>
              <div className="items">Item</div>
              <div className="charges">Charges</div>
              <div className="status">Status</div>
            </div>
            {payments &&
              payments.length > 0 &&
              payments.map((payment) => (
                <div className="item">
                  <div className="date">
                    {moment(new Date(payment.created_at)).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </div>
                  <div className="items">{payment.type}</div>
                  <div className="charges">${payment.price}</div>
                  <div className="status">Paid</div>
                </div>
              ))}
          </div>
        </div>
      )}
      {selected === "billing-details" && (
        <div className="billing-details-wrapper">
          <h3>Billing Details</h3>

          <div className="form-group">
            <label htmlFor="exampleFormControlSelect1">
              Country <span className="text-danger">*</span>
            </label>
            <select className="form-control" id="exampleFormControlSelect1">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="company">
              Company <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="company"
              aria-describedby="company"
            />
            <small id="company" class="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="street">
              Street Address <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="street"
              aria-describedby="street"
            />
          </div>
          <div class="form-group">
            <label htmlFor="unit">
              Unit/Suite <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              id="unit"
              aria-describedby="unit"
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">
              City/Town <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              aria-describedby="city"
            />
          </div>
          <div className="form-group">
            <label htmlFor="region">
              Region <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="region"
              aria-describedby="region"
            />
          </div>
          <div className="form-group">
            <label htmlFor="postalCode">
              Postal Code <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="postalCode"
              aria-describedby="postalCode"
            />
          </div>
          <div className="form-group">
            <label htmlFor="vatId">
              VAT ID <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="vatId"
              aria-describedby="vatId"
            />
          </div>
          <button>Save Settings</button>
        </div>
      )}
      {selected === "payment-methods" && (
        <div className="payment-methods-wrapper">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 existing-cards">
              <h3>Existing Cards</h3>
              {cards.map((card) => (
                <div className="existing-card" key={shortid.generate()}>
                  <div className="card-icon">
                    <img
                      src="https://www.mastercard.pl/content/dam/mccom/global/logos/logo-mastercard-mobile.svg"
                      className="img-fluid"
                      alt="card-logo"
                    />
                  </div>
                  <div className="card-text">
                    <div className="name">{card.name}</div>
                    <div className="ending">
                      MasterCard ending in {card.cardNumber} - {card.expiry}
                    </div>
                    <div className="status">
                      Use this card as default payment
                    </div>
                  </div>
                  <div className="remove-card">
                    <div className="icon">
                      <GrClose />
                    </div>
                    <div className="text">Delete this payment method</div>
                  </div>
                </div>
              ))}
            </div>
            <form
              onSubmit={handleSubmit}
              className="col-sm-12 col-md-12 col-lg-6 add-new-cards"
            >
              <h3>Add New Card</h3>
              <div className="form-group">
                <label htmlFor="card-name">
                  Name on Card <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  id="card-name"
                  aria-describedby="cardName"
                />
              </div>
              <div className="form-group">
                <label htmlFor="card-number">
                  Card Number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                  className="form-control"
                  id="card-number"
                  aria-describedby="cardNumber"
                />
              </div>
              <div className="half-width-inputs">
                <div className="form-group">
                  <label htmlFor="expiry">
                    Expiry <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    value={expiry}
                    onChange={(e) => setExpiry(e.target.value)}
                    className="form-control"
                    id="expiry"
                    placeholder="MM/YY"
                    aria-describedby="expiry"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cvv">
                    CVV <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    value={cvv}
                    onChange={(e) => setCVV(e.target.value)}
                    className="form-control"
                    id="cvv"
                    aria-describedby="cvv"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="post-code">
                    Postcode <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    value={postCode}
                    onChange={(e) => setPostCode(e.target.value)}
                    className="form-control"
                    id="post-code"
                    aria-describedby="postCode"
                  />
                </div>
              </div>
              <button type="submit">Add Card</button>
              <div className="info">
                Your card will not be charged at this time. But it will be set a
                default one for future charges
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethod;
