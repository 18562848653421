import React from "react";
import { TiMinus } from "react-icons/ti";

const HelpAndAdviceHeader = () => {
  return (
    <div className="help-and-advice-header">
      {/* <h2>HELP & ADVICE</h2> */}
      {/* <h1>HELP & ADVICE</h1> */}
      <h1>Help  & Advice</h1>
      <div className="minus-icons">
        <span>
          <TiMinus />
        </span>
        <span>
          <TiMinus />
        </span>
      </div>
      <div className="subtitle">
        <div>The Ultimate Guide to</div>
        <div>Wheelchair Accessible Vehicles</div>
      </div>
    </div>
  );
};

export default HelpAndAdviceHeader;
