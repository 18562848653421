import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Createaccount from './Createaccount';
import Resetpassword from './Resetpassword';
import Signin from './Signin';
import SocialLogin from './Sociallogin';
import './style.css'
import { ReactComponent as BackArrowSvg } from '../../images/back-arrow-navigation-svgrepo-com.svg'
import { backComponent, selectComponent, toggleModal } from '../../redux/actions/dealerActions';
import { ReactComponent as CloseSvg } from '../../images/close.svg'
import Checkmail from './Checkmail';
import Changepassword from './Changepassword';
import { useHistory } from 'react-router-dom';

const LoginComponent = ({ }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { selectedComponent } = useSelector((state) => state.dealers);

    useEffect(() => {

    }, [selectedComponent])

    return (
        <>
            <section className='auth_section_bg position-relative'>
                <div className='auth_section_bg_img' onClick={() => {
                    dispatch(toggleModal(false))
                    // document.body.classList.remove('overflow_hidden');
                }} ></div>
                <div className='sidebar_box'>
                    <div className='d-flex mb-3 justify-content-between align-items-center'>
                        <button className='comp_button d-inline-flex align-items-center' onClick={() => {
                            history.push("/")
                            dispatch(backComponent())
                        }
                        }><BackArrowSvg /> Back</button>
                        {/* <button className='comp_button' onClick={() => {
                            dispatch(toggleModal(false))
                            document.body.classList.remove('overflow_hidden');
                        }}>
                            <CloseSvg width="18px" height="18px" />
                        </button> */}
                    </div>
                    {
                        selectedComponent == 'signin'
                            ?
                            <Signin />
                            :
                            selectedComponent == 'social_login'
                                ?
                                <SocialLogin />
                                :
                                selectedComponent == 'reset_password' ?
                                    <Resetpassword /> :
                                    selectedComponent == 'create_account' ?
                                        <Createaccount /> :
                                        selectedComponent == 'check_email' ?
                                            <Checkmail />
                                            :
                                            selectedComponent == 'change_password' ?
                                                <Changepassword />
                                                :
                                                <SocialLogin />
                    }
                </div>
            </section>
        </>
    )
}
export default LoginComponent;