import React, { useEffect, useState } from "react";
import { AiOutlineClear } from "react-icons/ai";
import ArchiveSelect from "./ArchiveSelect";
import PriceSlider from "./PriceSlider";
import SearchResultsFilter from "./SearchResultsFilter";
import { filterWavsAccordingToPriceRange, getCheapestAndCostliestWavPrice } from "../../Helpers/utils";
import { getMakes, getModels } from "../../redux/actions/wavActions";
import { useDispatch, useSelector } from "react-redux";


function isJSONString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

const SearchSideBar = ({
  detailsFromState,
  setBody,
  setMake,
  setModel,
  setMileage,
  setSize,
  setTransmission,
  setSeating,
  setWheelchairs,
  setEntrance,
  setDoors,
  setYear,
  setPrice,
  // setArchive,
  search,
  // searchValue,
  // setSearchValue,
  setMinPrice,
  setMaxPrice,
  setPriceRange,
  // minPrice,
  wavs,
  // maxPrice,
  make,
  model,
  body,
  year,
  doors,
  entrance,
  seating,
  wheelchairs,
  size,
  mileage,
  // archive,
  price,
  priceRange,
  transmission,
  resetFilters,
  allWavs,
  priceSliderRange,
  setPriceSliderRange,
  filtered,
  setFiltered,
  filteredByMake,
  setFilteredByMake,
  filteredByModel,
  setFilteredByModel,
  handleFilterChange,
  wavsList
}) => {

  const dispatch = useDispatch();


  console.log("detailsFromState", detailsFromState)

  // console.log("mileage---", mileage)

  // console.log("priceRange", priceRange)
  // console.log("minPrice", minPrice)
  // console.log("maxPrice", maxPrice)


  const { makes: makesList, models: modelsList } = useSelector((state) => state.wavs);

  const [filteredModelsList, setFilteredModelsList] = useState([]);

  useEffect(() => {
    dispatch(getMakes());
    dispatch(getModels());
  }, [])

  useEffect(() => {
    setFilteredModelsList(modelsList)
  }, [modelsList])

  const filteredByBody =
    allWavs.length > 0 && allWavs.filter((wav) => wav.body === body);

  // const filteredByMake =
  //   body !== ""
  //     ? filteredByBody.filter((wav) => wav.make === make)
  //     :
  //     // allWavs.length > 0 &&
  //     allWavs.filter((wav) => wav.make === make);

  // const filteredByModel =
  //   make !== ""
  //     ? filteredByMake.filter((wav) => wav.models === model)
  //     :
  //     // allWavs.length > 0 &&
  //     allWavs.filter((wav) => wav.models === model);

  // const makes =
  //   body === ""
  //     ? allWavs.length > 0 && allWavs.map((wav) => wav.make)
  //     : filteredByBody.length > 0 && filteredByBody.map((wav) => wav.make);

  // const filteredMakes = [];
  // const uniqueMakes =
  //   makes &&
  //   makes.length > 0 &&
  //   makes.filter((make) => {
  //     const isUnique = filteredMakes.some((ele) => ele.trim().toLowerCase() == make.trim().toLowerCase());
  //     if (!isUnique) {
  //       filteredMakes.push(make);
  //     }
  //   });

    const filteredMakes = makesList.map((ele) => ele.title)

  // const models =
  //   make === ""
  //     ? allWavs.length > 0 && allWavs.map((wav) => wav.models)
  //     : filteredByMake.length > 0 && filteredByMake.map((wav) => wav.models);

  // const models = wavsList.map((wav) => wav.models)

  // const filteredModels = [];
  // const uniqueModels =
  //   models &&
  //   models.length > 0 &&
  //   models.filter((model) => {
  //     const isUnique = filteredModels.some((ele) => ele.trim().toLowerCase() == model.trim().toLowerCase());
  //     if (!isUnique) {
  //       filteredModels.push(model);
  //     }
  //   });

  const filteredModels = filteredModelsList.map((ele) => ele.title);


  console.log("priceRange", priceRange)

  return (
    <div>
      <div className="sidebar-header">
        {/* <h1>FILTER</h1> */}
        <h2>FILTER</h2>
        <p>Find exactly what you are looking for</p>
      </div>
      <div className="sidebar-main">
        <ul>
          {/* <li>
            <SearchResultsFilter
              name="BODY TYPE"
              list={detailsFromState.body}
              value={body}
              setValue={setBody}
            />
          </li> */}
          <li>
            <SearchResultsFilter
              name="MAKE"
              list={filteredMakes}
              value={make}
              setValue={(val)=>{
                setMake(val)

                let modelsListArr = val && val != "" ? modelsList.filter((ele) => ele.make_id.title.trim().toLowerCase() == val.trim().toLowerCase()) : modelsList
                setFilteredModelsList(modelsListArr)
              }}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="MODEL"
              list={filteredModels}
              value={model}
              setValue={setModel}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="MILEAGE"
              list={detailsFromState.mileage}
              value={mileage}
              setValue={setMileage}
            />
          </li>
          {/* <li>
            <SearchResultsFilter
              name="SIZE"
              list={detailsFromState.size}
              value={size}
              setValue={setSize}
            />
          </li> */}
          <li>
            <SearchResultsFilter
              name="TRANSMISSION"
              list={detailsFromState.transmission}
              value={transmission}
              setValue={setTransmission}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="SEATING"
              list={detailsFromState.seating}
              value={wheelchairs && wheelchairs != "" ? JSON.stringify({
                seats: seating,
                wheelchairs: wheelchairs
              }) : seating}
              setValue={(e) => {
                let isObjectString = isJSONString(e);
                
                if (isObjectString && JSON.parse(e).seats && JSON.parse(e).wheelchairs) {
                  setSeating(JSON.parse(e).seats)
                  setWheelchairs(JSON.parse(e).wheelchairs)
                }
                else {
                  setSeating(e)
                  setWheelchairs("")
                }
              }}
            />
          </li>
          {/* <li>
            <SearchResultsFilter
              name="ENTRANCE"
              list={detailsFromState.entrance}
              value={entrance}
              setValue={setEntrance}
            />
          </li> */}
          {/* <li>
            <SearchResultsFilter
              name="DOORS"
              list={detailsFromState.doors}
              value={doors}
              setValue={setDoors}
            />
          </li> */}
          {/* <li>
            <SearchResultsFilter
              name="YEAR"
              list={detailsFromState.year}
              value={year}
              setValue={setYear}
            />
          </li> */}
          <li>
            <PriceSlider
              value={priceRange}
              setValue={(priceRange) => {
                setPriceRange(priceRange)
                handleFilterChange(make, model, priceRange)

              }}
              priceSliderRange={priceSliderRange}
            // minPrice={minPrice}
            // maxPrice={maxPrice}
            // setMinPrice={setMinPrice}
            // setMaxPrice={setMaxPrice}
            />
          </li>
          {/* <li>
            <SearchResultsFilter
              name="PRICE"
              list={detailsFromState.price}
              setBody={null}
              setMake={null}
              setModel={null}
              setMileage={null}
              setSize={null}
              setTransmission={null}
              setSeating={null}
              setEntrance={null}
              setDoors={null}
              setYear={null}
              setPrice={setPrice}
            />
          </li> */}
          {/* {filterOptions.map((filterOption) => (
            <li key={shortid.generate()}>
              <SearchResultFilter
                name={filterOption.name}
                list={filterOption.list}
              />
            </li>
          ))} */}
        </ul>
        <ul id="filter-search">
          {/* <li>
            <div className="search__container">
              <input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="search__input"
                type="text"
                placeholder="Search"
              />
            </div>
          </li>
          <li>
            <div className="archive">
              <h3>ARCHIVE</h3>
              <ArchiveSelect  
                value={archive}
                setValue={setArchive}
              />
            </div>
          </li> */}
          <li>
            <button className="filter-search-btn" onClick={() => search()}>
              Search
            </button>
            <button onClick={() => resetFilters()} className="filter-search-btn ml-3">
              <AiOutlineClear /> Clear
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SearchSideBar;
