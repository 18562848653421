import React from "react";
import DealerPageNavPanelCard from "./DealerPageNavPanelCard";
const DealerPageNavPanelCards = ({ wavs }) => {
  return (
    <div className="dealer-page-nav-panel-cards my-2">
      <div className="row">
        {wavs.map((wav) => (
          <div key={wav._id} className="col-sm-6 col-md-6 col-lg-4 mt-3">
            <DealerPageNavPanelCard wav={wav} />
          </div>
        ))}
      </div>
      <button className="show-more">SHOW MORE</button>
    </div>
  );
};

export default DealerPageNavPanelCards;
