import {
  GET_DEALERS,
  GET_DEALER,
  CREATE_DEALER,
  LOGIN_DEALER,
  LOGOUT_DEALER,
  MODAL_STATE,
  SELECT_COMPONENT,
  BACK_COMPONENT
} from "../constants/constants";
import Cookies from "universal-cookie";

const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;

export const getDealers = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/dealer/all`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_DEALERS, payload: data.data.dealers });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const verifyResetPasswordToken = (token) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/dealer/verifyResetPasswordToken/${token}`, {
        method: "GET",
      });


      if (response.ok) {
        const data = await response.json();

        return data;
      }
      else {
        const data = await response.json();
        if (data.message == 'dealer_404') {
          return {
            status: "error",
            error:
              "Please check it's been entered correctly or create a new account",
          };
        }
        else {
          return {
            status: "error",
            error:
              "Something went wrong",
          };
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const changePassword = (body) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/dealer/changePassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });


      if (response.ok) {
        const data = await response.json();

        return data;
      }
      else {
        const data = await response.json();
        if (data.message == 'dealer_404') {
          return {
            status: "error",
            error:
              "Password didnot changed",
          };
        }
        else {
          return {
            status: "error",
            error:
              "Something went wrong",
          };
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};


export const forgotPassword = (email) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/dealer/forgotPassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(email),
      });


      if (response.ok) {
        const data = await response.json();

        return data;
      }
      else {
        const data = await response.json();
        if (data.message == 'dealer_404') {
          return {
            status: "error",
            error:
              "Please check it's been entered correctly or create a new account",
          };
        }
        else {
          return {
            status: "error",
            error:
              "Something went wrong",
          };
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDealer = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/dealer/${id}`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_DEALER, payload: data.data.dealer });
        return data.data.dealer;
      }
    } catch (err) {
      console.log(err);
    }
  };
};


export const socialLogin = (dealer) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}dealer/socialLogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dealer),
      });

      if (response.ok) {
        const data = await response.json();

        const dealer = {
          accountDetails: data.data.newDealer,
          token: data.data.token,
        };
        dispatch({ type: CREATE_DEALER, payload: dealer });
        const cookies = new Cookies();
        const today = new Date();
        const tomorrow = new Date(today);
        const expiryDate = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
        cookies.set("jwt", data.data.token, { expires: expiryDate });
        cookies.set("user", data.data.newDealer, { expires: expiryDate });
        return { status: "success", id: data.data.newDealer._id };
      } else {
        const data = await response.json();

        if (data.error.code === 11000) {
          console.log("error: ", data.error.code);
          console.log(
            "Account  already exists. Please log in or sign up with another email"
          );
          return {
            status: "error",
            error:
              "Account with similar email already exists. Please log in or sign up with another email",
          };
        }
        if (data.error.details[0].context.key === "password") {
          return {
            status: "error",
            error:
              "Please make sure you type at least 8 characters for the password",
          };
        }
        return { status: "error", error: data.error };
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const createDealer = (dealer) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/dealer/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dealer),
      });

      if (response.ok) {
        const data = await response.json();

        const dealer = {
          accountDetails: data.data.newDealer,
          token: data.data.token,
        };
        dispatch({ type: CREATE_DEALER, payload: dealer });
        const cookies = new Cookies();
        const today = new Date();
        const tomorrow = new Date(today);
        const expiryDate = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
        cookies.set("jwt", data.data.token, { expires: expiryDate });
        cookies.set("user", data.data.newDealer, { expires: expiryDate });
        return { status: "success", id: data.data.newDealer._id };
      } else {
        const data = await response.json();

        if (data.error.code === 11000) {
          console.log("error: ", data.error.code);
          console.log(
            "Account  already exists. Please log in or sign up with another email"
          );
          return {
            status: "error",
            error:
              "Sorry, there's already an account associated with that email"
            // "Account with similar email already exists. Please log in or sign up with another email",
          };
        }
        if (data.error.details[0].context.key === "password") {
          return {
            status: "error",
            error:
              "Please make sure you type at least 8 characters for the password",
          };
        }
        return { status: "error", error: data.error };
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const loginDealer = (dealer) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/dealer/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dealer),
      });

      if (response.ok) {
        const data = await response.json();

        const dealer = {
          accountDetails: data.data.dealer,
          token: data.data.token,
        };
        dispatch({ type: LOGIN_DEALER, payload: dealer });
        const cookies = new Cookies();
        const today = new Date();
        const tomorrow = new Date(today);
        const expiryDate = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
        cookies.set("jwt", data.data.token, { expires: expiryDate });
        cookies.set("user", data.data.dealer, { expires: expiryDate });
        return { status: "success", id: data.data.dealer._id };
      } else {
        const data = await response.json();
        console.log("error: ", data.error);
        return { status: "error", error: data.error };
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const toggleModal = (bool) => {
  if (!bool) {
    document.body.classList.remove('overflow_hidden');
  }
  else {
    document.body.classList.add('overflow_hidden');
  }
  return async (dispatch) => {
    dispatch({ type: MODAL_STATE, payload: bool })
  }
}

export const selectComponent = (val) => {
  return async (dispatch) => {
    dispatch({ type: SELECT_COMPONENT, payload: val })
  }
}

export const backComponent = () => {
  return async (dispatch) => {
    dispatch({ type: BACK_COMPONENT })
  }
}


export const logoutDealer = () => {
  return async (dispatch) => {
    try {
      const cookies = new Cookies();
      // cookies.remove("jwt");
      // cookies.remove("user");
      cookies.remove("jwt", { path: '/' });
      cookies.remove("user", { path: '/' });
      dispatch({ type: LOGOUT_DEALER });
    } catch (err) {
      console.log(err);
    }
  };
};
// old_password:
// new_password:
export const updateDealer = (name, login, logo, id) => {
  return async (dispatch) => {
    const cookies = new Cookies();
    const token = cookies.get("jwt");

    let formData = new FormData();
    let file = logo.file;
    formData.append("logo", file);
    formData.append("name", name);
    formData.append("login", login);

    try {
      const response = await fetch(`${url}/dealer/${id}`, {
        method: "PATCH",
        body: formData,
        headers: {
          authorization: token,
        },
      });
      if (response.ok) {
      } else {
        const data = await response.json();
        console.log("error: ", data);
      }
    } catch (err) {
      console.log(err);
    }
  };
};
